import React from 'react'
import tailwind from 'twin.macro'
import styled from 'styled-components/macro'
import ApplePay from './ApplePay'
import CreditCard from './CreditCard'
import PaymentMethods from './Methods'
import MethodAlert from './MethodAlert'
import Pix from './Pix'
import { HeadingContainer } from 'components/Summary/styles'

const Heading = styled.h3`
   ${tailwind`
    text-lg
    font-medium
    text-gray-900
  `}
`

const Container = styled.div`
   ${tailwind`
    mt-10
  `}
`

const MainHeading = styled.div`
   ${tailwind`
    flex
    flex-row
    `}
`

const TextContainer = styled.div`
   ${tailwind`
      flex
      flex-col
      justify-center
      object-center
      w-full
      h-full
   `}
   h3 {
      ${tailwind`
         text-base
         font-semibold
         text-gray-900
      `}
   }
`

const ButtonContainer = styled.div`
   ${tailwind`
      pr-2

      flex
      items-center
   `}
`

type PaymentProps = {
   control: any
   register: Function
   errors: any
}

export default function Payment({ control, register, errors }: PaymentProps) {
   return (
      <>
         <Container>
            <MainHeading>
               <ButtonContainer>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M9.91666 6.41667V4.66667C9.91666 3.05584 8.61083 1.75 7 1.75C5.38916 1.75 4.08333 3.05584 4.08333 4.66667V6.41667M5.13333 12.25H8.86666C9.84675 12.25 10.3368 12.25 10.7111 12.0593C11.0404 11.8915 11.3081 11.6238 11.4759 11.2945C11.6667 10.9201 11.6667 10.4301 11.6667 9.45V9.21667C11.6667 8.23658 11.6667 7.74653 11.4759 7.37218C11.3081 7.0429 11.0404 6.77518 10.7111 6.60741C10.3368 6.41667 9.84675 6.41667 8.86666 6.41667H5.13333C4.15324 6.41667 3.66319 6.41667 3.28884 6.60741C2.95956 6.77518 2.69185 7.0429 2.52407 7.37218C2.33333 7.74653 2.33333 8.23658 2.33333 9.21667V9.45C2.33333 10.4301 2.33333 10.9201 2.52407 11.2945C2.69185 11.6238 2.95956 11.8915 3.28884 12.0593C3.66319 12.25 4.15324 12.25 5.13333 12.25Z"
                        stroke="#EA476A"
                        stroke-width="1.33"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                     />
                  </svg>
               </ButtonContainer>
               <TextContainer>
                  <Heading>Dados do cartão</Heading>
               </TextContainer>
            </MainHeading>
            <PaymentMethods />
            <MethodAlert />
            <ApplePay />
            <Pix control={control} register={register} errors={errors?.pix} />
            <CreditCard control={control} register={register} errors={errors} />
         </Container>
      </>
   )
}

import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import {
  Label,
  InputContainer,
  InputIconContainer,
  Input,
  InputIconErrorContainer,
  InputError,
} from "./styles";

const CREDIT_CARD_EXPIRATION_DATE_INPUT = "card.expiration_date";

type ExpirationDateProps = {
  control: any;
  errors: any;
};

function validateExpirationDate(value: string) {
  const digits = value?.replace(/[^\d]/g, "");

  if (digits.length < 4) {
    return "Data de expiração inválida.";
  }

  const month = parseInt(digits.substring(0, 2), 10);
  if (month > 12) {
    return "Mês inválido.";
  }

  const year = parseInt(digits.substring(2, 4), 10) + 2000;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  if (year.toString().length === 4 && year < currentYear) {
    return "Ano inválido.";
  }

  if (currentYear === year && month < currentMonth) {
    return "Data de expiração inválida.";
  }
}

export default function ExpirationDate({
  control,
  errors,
}: ExpirationDateProps) {
  return (
    <>
      <Label error={errors} htmlFor={CREDIT_CARD_EXPIRATION_DATE_INPUT}>
        Validade
      </Label>
      <InputContainer>
        <InputIconContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </InputIconContainer>
        <Controller
          name={CREDIT_CARD_EXPIRATION_DATE_INPUT}
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: "Insira a data de expiração.",
            },
            validate: validateExpirationDate,
          }}
          render={({ field: { value, onChange, onBlur, ref } }) => (
            <NumberFormat
              format="##/##"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              customInput={Input}
              autoComplete="cc-exp"
              error={errors}
              placeholder="mm/aa"
              getInputRef={ref}
            />
          )}
        />
        {errors && (
          <InputIconErrorContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </InputIconErrorContainer>
        )}
      </InputContainer>
      {errors && <InputError>{errors?.message}</InputError>}
    </>
  );
}

import React from "react";
import { Grid, Heading, SecondHeading } from "./styles";
import Email from "./Email";
import Phone from "./Phone";
import Name from "./Name";
import { useOrder } from "contexts/order";

type CustomerProps = {
  control: any;
  register: Function;
  errors: any;
};

export default function Customer({ control, register, errors }: CustomerProps) {
  // const { customer } = useOrder();
  return (
    <>
      <Heading>Complete seu cadastro</Heading>
      <SecondHeading>Para prosseguir, preencha os dados abaixo.</SecondHeading>

      <Grid>
       
        <Email
          register={register}
          errors={errors?.email}
          email={'email'}
        />
        <Phone
          control={control}
          errors={errors?.phone}
          phone={'phone'}
        />
      </Grid>
    </>
  );
}

import React, { Fragment } from "react";
import tailwind from "twin.macro";
import styled from "styled-components";
import Modal from "components/Modal/Modal";
import { Theme } from "themes/types";

const Icon = styled.div`
  ${tailwind`
    mx-auto
    flex
    items-center
    justify-center
    h-12
    w-12
    rounded-full
    bg-red-700
  `}

  svg {
    ${tailwind`
      animate-pulse
      h-6
      w-6
      text-white
    `}
  }
`;

const Content = styled.div`
  ${tailwind`
    mt-4
  `}

  p {
    ${tailwind`
      text-sm
      text-gray-500
      mb-3
    `}
  }
`;

const Heading = styled.h3`
  ${tailwind`
    text-lg
    leading-6
    font-medium
    text-gray-900
  `}
`;

const Container = styled.div`
  ${tailwind`
    mt-3
    text-center
    sm:mt-5
  `}
`;

const Button = styled.button`
  ${tailwind`
    appearance-none
    mt-5
    sm:mt-6
    inline-flex
    justify-center
    w-full
    rounded-md
    border
    border-transparent
    shadow-sm
    px-4
    py-2
    text-base
    font-medium
    text-white
    hover:bg-pink-600
    sm:text-sm
  `}
  ${({ theme }: { theme: Theme }) =>
    `
    background: ${theme.colors.primary};
  `}
`;

type PaymentErrorProps = {
  visible: boolean;
  msgError?: string | null;
  setVisibility: (value: boolean) => void;
};

export default function PaymentErrorModal({ visible, setVisibility, msgError }: PaymentErrorProps) {

  if (!visible) {
    return <Fragment />;
  }

  return (
    <Modal>
      <Icon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </Icon>
      <Container>
        <Heading>Falha ao Processar Pagamento</Heading>
        <Content>
          <p>
            {msgError || 'Não foi possível processar seu pagamento. Pode fazer uma nova tentativa?'}
          </p>
          <Button onClick={() => setVisibility(false)}>
            {msgError ? 'Entendi' : 'Irei tentar novamente'}
          </Button>
        </Content>
      </Container>
    </Modal>
  );
}

// @ts-nocheck

import { Controller } from "react-hook-form";
import {
  Label,
  InputContainer,
  InputIconContainer,
  Input,
  InputIconErrorContainer,
  InputError,
  InputGroupContainer,
} from "./styles";
import { validateDocument } from "utils/validators/document";
import { documentMask } from "utils/masks";
import NumberFormat from "react-number-format";

const INPUT_CREDIT_CARD_DOCUMENT_NAME = "card.document";

type DocumentProps = {
  control: Function;
  errors: any;
};

export default function Document({ control, errors }: DocumentProps) {
  return (
    <InputGroupContainer>
      <Label error={errors} htmlFor={INPUT_CREDIT_CARD_DOCUMENT_NAME}>
        CPF/CNPJ Titular
      </Label>
      <InputContainer>
        <InputIconContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            />
          </svg>
        </InputIconContainer>
        <Controller
          name={INPUT_CREDIT_CARD_DOCUMENT_NAME}
          control={control}
          defaultValue=""
          
          rules={{
            required: {
              value: true,
              message: "Insira o CPF ou CNPJ do titular do cartão.",
            },
            validate: validateDocument,
          }}
          render={({ field: { value, onChange, onBlur, ref } }) => (
            <NumberFormat
              format={documentMask(value)}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              customInput={Input}
              error={errors}
              getInputRef={ref}
            />
          )}
        />
        {errors && (
          <InputIconErrorContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </InputIconErrorContainer>
        )}
      </InputContainer>
      {errors && <InputError>{errors?.message}</InputError>}
    </InputGroupContainer>
  );
}

import styled from 'styled-components';
import { css } from 'styled-components';

export const Wrapper = styled.div<{ type?: string }>`
  margin: auto;
  height: 190px;
  perspective: 62.5rem;
  background: transparent;
  width: min(100%, 18.75rem);

  &.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  div.perspective {
    width: 100%;
    height: 100%;
    position: relative;
    transition: transform 0.8s;
    transform-style: preserve-3d;

    &.flip {
      transform: rotateY(180deg);
    }

    div.face {
      ${({ type, theme }) => {
        return {
          mastercard: css`
            color: white;
            background: rgb(235, 0, 27);
            background: linear-gradient(
              0deg,
              rgba(235, 0, 27, 1) 0%,
              rgba(255, 95, 0, 1) 50%,
              rgba(247, 158, 27, 1) 100%
            );
          `,
          elo: css`
            color: white;
            background: rgb(7, 167, 226);
            background: linear-gradient(
              0deg,
              rgba(7, 167, 226, 1) 0%,
              rgba(241, 66, 38, 1) 50%,
              rgba(255, 221, 90, 1) 100%
            );
          `,
          visa: css`
            color: white;
            background: rgb(35, 41, 98);
            background: linear-gradient(
              0deg,
              rgba(35, 41, 98, 1) 0%,
              rgba(32, 126, 210, 1) 95%,
              rgba(20, 110, 200, 0.9) 10%
              
            );
          `,
          'american-express': css`
            color: white;
            background: #0079c1;
          `,
          'hiper-card': css`
            color: white;
            background: #b3131b;
          `,
          hiper: css`
            color: white;
            background: #eb5d15;
          `,
          default: css`
            color: #cbcbcb;
            background: #999999;
          `,
          jcb: css`
            color: #3B4E79;
            background-image: linear-gradient(
              135deg,
              #ffff71 0,
              #fff866 16.67%,
              #ffdc5c 33.33%,
              #f2bd53 50%,
              #dca04b 66.67%,
              #c78846 83.33%,
              #b57442 100%
            );
          `,
          ticket: css`
            background: linear-gradient(
              45deg,
              rgba(113, 187, 86, 1) 0%,
              rgba(83, 177, 98, 1) 50%,
              rgba(42, 164, 116, 1) 100%
            );
            color: #ffffff;
          `,
          goodcard: css`
            background: linear-gradient(
              45deg,
              rgba(104, 206, 212, 1) 0%,
              rgba(48, 155, 207, 1) 65%,
              rgba(255, 255, 255, 1) 100%
            );
            color: #ffffff;
          `,
        }[type || 'default'];
      }}
    }
  }
`;

export const CardFace = styled.div`
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  border-radius: 0.5rem;
  flex-direction: column;
  backface-visibility: hidden;
  padding: 1.5rem 1.75rem 1.5rem 1.75rem;

  &.back {
    transform: rotateY(180deg);
  }

  div.icon-flag {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img.flag {
      width: auto;
      height: auto;
      max-width: 3.75rem;
      max-height: 2.25rem;
    }
  }

  div.number {
    margin-top: 0.5rem;
  }

  div.cvv {
    margin-top: auto;
  }

  div.card-bottom {
    display: flex;
    margin-top: auto;
    align-items: center;
    justify-content: space-between;

    & > p {
      overflow: hidden;
      max-width: 9.75rem;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const WChipIcon = styled.div`
  width: 2.75rem;
  height: 2.2rem;
  position: relative;

  svg {
    z-index: 1;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &:before {
    left: 0;
    z-index: 0;
    content: '';
    width: 100%;
    top: 0.063rem;
    position: absolute;
    border-radius: 0.5rem;
    height: calc(100% - 0.125rem);
    background: linear-gradient(
      0deg,
      rgba(214, 148, 100, 1) 0%,
      rgba(227, 164, 95, 1) 35%,
      rgba(251, 196, 90, 1) 100%
    );
  }
`;

import React, { Fragment } from 'react';
import { Grid } from './styles';
import { usePaymentMethod } from 'contexts/payment-method';
import Name from './Name';
import Document from './Document';

type PixProps = {
  control: Function;
  register: Function;
  errors: any;
};

export default function Pix({ control, register, errors }: PixProps) {

  const { paymentMethod } = usePaymentMethod();

  if (paymentMethod !== 'pix') {
    return <Fragment />;
  }

  return (
    <Grid>
      <Name
        register={register}
        errors={errors?.name}
      />
      <Document
        control={control}
        errors={errors?.document}
      />
    </Grid>
  );
}

export const getStore = (id) => {

    const lojas = [
        {
            id: "724",
            name: "Multi Óleos - Serviços Automotivos",
            pixAddressKey: "34941156000199",
            pixAddressKeyType: "CNPJ",
            address: {
                zipCode: "70680200",
                number: "01",
                address: "QMSW 02 conjunto B lote 01",
                neighborhood: "Cruzeiro / Sudoeste / Octogonal",
                city: "Brasília",
            },
            off: true,
        },{
            id: "725",
            name: "Super Express",
            pixAddressKey: "33601941000130",
            pixAddressKeyType: "CNPJ",
            address: {
                zipCode: "70730516",
                number: "51",
                address: "SCLRN 703 BL F - loja 51",
                neighborhood: "Asa Norte",
                city: "Brasília",
            },
            off: true,
        },{
            id: "730",
            name: "PH Peças e Serviços",
            pixAddressKey: "39682310000179",
            pixAddressKeyType: "CNPJ",
            address: {
                zipCode: "70720630",
                number: "14",
                address: "St. de Habitações Coletivas e Geminadas Norte 706 BL C - loja 14",
                neighborhood: "Asa Norte",
                city: "Brasília",
            },
            off: true,
        },{
            id: "733",
            name: "Placidus Personal Car",
            pixAddressKey: "45287566000184",
            pixAddressKeyType: "CNPJ",
            address: {
                zipCode: "72115170",
                number: "04",
                address: "Taguatinga Norte QNB 17 Lote 04",
                neighborhood: "Taguatinga",
                city: "Brasília",
            },
            off: true,
        },{
            id: "736",
            name: "Jacaúna Auto Center",
            pixAddressKey: "28735490000193",
            pixAddressKeyType: "CNPJ",
            address: {
                zipCode: "72006252",
                number: "256",
                address: "Setor de Chácaras, Condomínio Bem-Te-Vi, 256",
                neighborhood: "Vicente Pires",
                city: "Brasília",
            },
            off: true,
        },{
            id: "737",
            name: "FM Soluções Automotivas",
            pixAddressKey: "61995038775",
            pixAddressKeyType: "PHONE",
            address: {
                zipCode: "70730515",
                number: "20",
                address: "SCLRN 703 bloco E - loja 20 e 26",
                neighborhood: "Asa Norte",
                city: "Brasília",
            },
            off: true,
        },{
            id: "738",
            name: "JC Autocenter",
            pixAddressKey: "35893618000102",
            pixAddressKeyType: "CNPJ",
            address: {
                zipCode: "71256215",
                number: "13",
                address: "Quadra 5 Conjunto 13, 10 , Setor Oeste",
                neighborhood: "Vila Estrutural",
                city: "Brasília",
            },
            off: true,
        },{
            id: "739",
            name: "Manutenção Automotiva ",
            pixAddressKey: "61992338184",
            pixAddressKeyType: "PHONE",
            address: {
                zipCode: "72006252",
                number: "03",
                address: "Cse 06 - lote 80 - loja 03",
                neighborhood: "Taguatinga Sul ",
                city: "Brasília",
            },
            off: true,
        },{
            id: "741",
            name: "R7 Martelinho de Ouro",
            pixAddressKey: "04455733175",
            pixAddressKeyType: "CPF",
            address: {
                zipCode: "72113330",
                number: "18",
                address: "SHVP Rua 7, Chácara 330 Loja 18",
                neighborhood: " Vicente Pires",
                city: "Brasília",
            },
            off: true,
        },{
            id: "742",
            name: "Oficina do Alemão",
            pixAddressKey: "482812180128",
            pixAddressKeyType: "CPF",
            address: {
                zipCode: "72120430",
                number: "04",
                address: "Quadra Qnd 43 Lote 02 Loja 04",
                neighborhood: "Taguatinga Norte",
                city: "Brasília",
            },
            off: true,
        },{
            id: "743",
            name: "Centro Automotivo Rosário",
            pixAddressKey: "35739012000117",
            pixAddressKeyType: "CNPJ",
            address: {
                zipCode: "72911208",
                number: "47",
                address: "Avenida Cuiaba Quadra 47 Conjunto A, 05, SETOR 01, Parque da Barragem - Jardim Querência",
                neighborhood: "Águas Lindas",
                city: "Goiás",
            },
            off: true,
        },{
            id: "755",
            name: "Podium Centro Automotivo",
            pixAddressKey: "74752707691",
            pixAddressKeyType: "CPF",
            address: {
                zipCode: "72125710",
                number: "02",
                address: "Lote 40 - Loja 02 - Sandu Norte",
                neighborhood: "Taguatinga Norte",
                city: "Brasília",
            },
            off: true,
        },{
            id: "756",
            name: "WG Auto Centro",
            pixAddressKey: "61996801451",
            pixAddressKeyType: "PHONE",
            address: {
                zipCode: "72230522",
                number: "05",
                address: "ENTRE QUADRA EQNP (COMERCIO) 8/12 BLOCO B 05, CEILANDIA SUL",
                neighborhood: "Ceilândia",
                city: "Brasília",
            },
            off: true,
        },{
            id: "758",
            name: "Renovacar Lanternagem e Pintura",
            pixAddressKey: "61995823483",
            pixAddressKeyType: "PHONE",
            address: {
                zipCode: "71692100",
                number: "102",
                address: "QUADRA 102, 01 SETOR RESIDENCIAL OESTE",
                neighborhood: "São Sebastião",
                city: "Brasília",
            },
            off: true,
        },{
            id: "762",
            name: "Manutenção Veícular - Simulações",
            pixAddressKey: "11898123000108",
            pixAddressKeyType: "CNPJ",
            address: {
                zipCode: "71070625",
                number: "02",
                address: "Area Especial 2-A, 02",
                neighborhood: "Guara II",
                city: "Brasília",
            },
            off: false,
        },{
            id: "770",
            name: "Rock Garage Manutenção Automotiva",
            pixAddressKey: "32194984000185",
            pixAddressKeyType: "CNPJ",
            address: {
                zipCode: "12031690",
                number: "34",
                address: "Rua Amaro Negrini 34",
                neighborhood: "Taubaté",
                city: "São Paulo",
            },
            off: true,
        },{
            id: "772",
            name: "Odair Pintura e Lanternagem LTDA ",
            pixAddressKey: "28578674000197",
            pixAddressKeyType: "CNPJ",
            address: {
                zipCode: "74475060",
                number: "12",
                address: "Rua F SN Quadra 279 Lote 12",
                neighborhood: "Setor Parque Tremendao",
                city: "Goiânia",
            },
            off: true,
        },{
            id: "778",
            name: "GD DIESEL MANUTENCAO 4X4",
            pixAddressKey: "",
            pixAddressKeyType: "",
            address: {
                zipCode: "65055140",
                number: "81",
                address: "Rua Conego Ribamar Carvalho",
                neighborhood: "Jardim Sao Cristovao",
                city: "São Luís",
            },
            off: true,
        },{
            id: "779",
            name: "Paulinho Car Peças e Serviços LTDA",
            pixAddressKey: "",
            pixAddressKeyType: "",
            address: {
                zipCode: "70790050",
                number: "81",
                address: "Sgan Quadra 905 Conjunto A Parte 02",
                neighborhood: "Asa Norte",
                city: "Brasília",
            },
            off: true,
        },{
            id: "197",
            name: "R2 Motors (sandbox)",
            pixAddressKey: "01720374171",
            pixAddressKeyType: "CPF",
            address: {
                zipCode: "06233200",
                number: "3003",
                address: "Av. das Nações Unidas",
                neighborhood: "Bonfim",
                city: "Osasco",
            },
            off: false,
        },{
            id: "26",
            name: "R2 Motors (local)",
            pixAddressKey: "61983336336",
            pixAddressKeyType: "PHONE",
            address: {
                zipCode: "06233200",
                number: "3003",
                address: "Av. das Nações Unidas",
                neighborhood: "Bonfim",
                city: "Osasco",
            },
            off: false,
        }
    ]


    return (lojas.find(est => est.id === id));
}
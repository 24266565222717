import { Theme } from "./types";

// const { store } = useOrder();

const zapay: Theme = {
  coupon: true,
  whitelabel: false,
  displayFees: false,
  logo: "zapay.png",
  colors: {
    primary: "#fe0060",
    secondary: "#e91e63",
  },
};

export default zapay;

// @ts-nocheck

import creditCardType from 'credit-card-type';
import first from 'lodash/first';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { Props } from './types';
import { CARD_FLAGS, DEFAULT_VALUES } from './constants';
import { Format, getNationalCardIssuer, Normalize } from './utils';
import { usePaymentMethod } from 'contexts/payment-method';
import { useCreditCardFlip } from 'contexts/credit-card-flip';

export const useModel = ({ control, fliped }: Props) => {
  const { flip } = useCreditCardFlip();
  const { paymentMethod: type } = usePaymentMethod();

  const fieldsWatch = useWatch({
    control,
    name: [
      `card.number`,
      `card.expiration_date`,
      `card.cvv`,
      `card.holder`,
      `card.flip`,
    ],
  });

  const fields = useMemo(() => {
    const [fieldNumber, fieldValidity, fieldCVV, fieldHolderName] =
      fieldsWatch;

    const onlyNumber = fieldNumber?.replace(/[^\d]/g, '');

    const number = Format.number(onlyNumber);
    const issuer =
      onlyNumber?.length >= 4
        ? getNationalCardIssuer(
            first(creditCardType(number))?.type,
            type,
            onlyNumber,
          )
        : undefined;

    const flag = issuer ? CARD_FLAGS[issuer] : undefined;

    return {
      flag,
      issuer,
      flip: fliped || flip,
      cvv: Normalize.cvv(fieldCVV, onlyNumber) || '••••',
      validity: Normalize.validity(fieldValidity) || '••/••',
      number: Normalize.number(number) || DEFAULT_VALUES.number,
      holderName: String(fieldHolderName || 'Nome no Cartão').toUpperCase(),
    };
  }, [fieldsWatch, type, flip, fliped]);

  return {
    ...fields,
  };
};

import tailwind from 'twin.macro'
import styled from 'styled-components'
import TotalAmount from 'components/Amount/Total'
import Modal from 'components/Modal/Modal'
import { Theme } from 'themes/types'
import { CardInfos } from '../CreditCard/card-infos'
import Receipt from 'pages/ProofPayment/ProofPayment'
import Header from 'components/Header'

const Icon = styled.div`
   ${tailwind`
      fixed
      right-8
      top-8
  `}

   svg {
      ${tailwind`
      animate-pulse
      h-6
      w-6
      text-white
      md:w-8
      md:h-8
    `}
   }
`

const Content = styled.div`
   ${tailwind`
    mt-4
  `}

   & > p {
      ${tailwind`
      text-sm
      text-gray-500
      mb-3
    `}
   }
`

const Heading = styled.h3`
   ${tailwind`
      flex
      justify-between
  `}
`

const Subheading = styled.h4`
   ${tailwind`
    uppercase
    tracking-wide
    text-sm
    text-red-600
    font-bold
    my-2
  `}
`

const Container = styled.div`
   ${tailwind`
     mx-2
  `}
`

const Button = styled.button`
   ${tailwind`
    appearance-none
    mt-5
    sm:mt-6
    inline-flex
    justify-center
    w-full
    rounded-md
    border
    border-transparent
    shadow-sm
    px-4
    py-2
    text-base
    font-medium
    text-white
    hover:bg-pink-600
    sm:text-sm
  `}
   ${({ theme }: { theme: Theme }) =>
      `
    background: ${theme.colors.primary};
  `}
`

const X = styled.button`
   ${tailwind`

  `}
`

export default function ReceiptModal({ data, display, hide }) {
   return (
      <Modal hidden={!display}>
         <Container>
            <Heading>
               <Header />
               <Icon onClick={() => hide()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <g id="x-close">
                        <path
                           id="Icon"
                           d="M18 6L6 18M6 6L18 18"
                           stroke="#2D161E"
                           stroke-width="2"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                        />
                     </g>
                  </svg>
               </Icon>
            </Heading>

            <Receipt data={data} />
         </Container>

         {/* <Icon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </Icon>
      <Container>
        <Heading>Pagamento Não Autorizado</Heading>
        <Content>
          <p>
            Vamos fazer uma nova tentativa? Antes de continuar, verifique os
            pontos abaixo.
          </p>
          <Subheading>1. Número e Validade do Cartão</Subheading>
          <p>O número do cartão e a validade estão corretos?</p>
          <CardInfos control={control} />
          <Subheading>2. Código de Segurança do Cartão</Subheading>
          <p>O código de segurança do cartão foi digitado corretamente?</p>
          <CardInfos control={control} fliped />
          <Subheading>3. O cartão tem limite disponível?</Subheading>
          <b>
            <TotalAmount />
          </b>
          <p>
            O valor do pagamento não excede o limite disponível no seu cartão?
          </p>
          <hr className="my-4" />
          <p>
            Após verificar esses pontos, faça uma nova tentativa ou conclua seu
            pagamento com outro cartão de crédito.
          </p>
          <Button onClick={() => hide()}>Irei tentar novamente</Button>
        </Content>
      </Container> */}
      </Modal>
   )
}

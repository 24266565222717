import styled from "styled-components/macro";
import { useTheme } from "styled-components";
import { Theme } from "themes/types";
import tailwind from "twin.macro";
import WhiteLabelLogo from "./WhiteLabelLogo";
import { LogoZapay } from "components/logo-zapay";
import {ZapayLogo} from "components/zapay-logo";
import { useHistory, useParams } from "react-router";

const StyledHeader = styled.header`
  ${tailwind`
    pb-2
    pt-6
    flex
    gap-x-8
    items-center
    lg:px-0
    lg:pt-14
    lg:pb-4
    cursor-pointer
  `}

  ${({ theme }: { theme: Theme }) => !!theme?.cssLogos && `
    ${theme.cssLogos};
  `}
`;

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;
  position: fixed;
  background-color: white;
  border-bottom: 1px solid #BCCFE5;

  ${tailwind`
    lg:grid
    lg:grid-cols-10
    xl:pl-28
    xl:mr-12
    lg:px-8
  `}

  div.w-container {
    width: 100%;
    display: flex;
    padding: 1rem 0;
    max-width: 996px;

    ${tailwind`
        lg:col-span-7
        lg:mx-auto
        lg:pr-20
        lg:row-start-1
        lg:col-start-1
    `}

    svg {
      width: 94px;
    }
  }
`;

export default function Header() {
  const { header } = useTheme();
  const history = useHistory();
  const { id } = useParams<{ id: string }>()

  if (!!header?.modBar) {
    return (
      <Wrapper>
        <div className="w-container">
          <WhiteLabelLogo />
          {header?.notZapay ? null : (
            <ZapayLogo />
          )}
        </div>
      </Wrapper>
    );
  }

  return (
    <StyledHeader className="mode-bar" onClick={() => {history.push(`/${id}`)}}>
      <WhiteLabelLogo />
      {header?.notZapay ? null : (
        <LogoZapay />
      )}
    </StyledHeader>
  );
}

import { useOrder } from "contexts/order";
import { Container, Link } from "./styles";


export default function Agreement() {
  const { store } = useOrder();

  const terms = store?.settings?.terms || 'https://legal.usezapay.com.br/zapay/terms';
  const policy = store?.settings?.policy || 'https://legal.usezapay.com.br/zapay/policy';

  return (
    <Container>
      Ao clicar no botão abaixo, eu declaro que li e estou de acordo com os
      <Link href={terms} target="_blank">
        Termos de Serviço
      </Link>{" "}
      e a
      <Link
        href={policy}
        target="_blank"
      >
        Política de Privacidade
      </Link>{" "}
      da Zapay Serviços de Pagamentos S.A.
    </Container>
  );
}

import React,  { ReactNode, createContext, useState, useContext } from 'react';

type CreditCardFlipContextType = {
  flip: boolean;
  setCreditCardFlip: (value: boolean) => void
};

const DEFAULT = false;

const CreditCardFlipContext = createContext<CreditCardFlipContextType>({
  flip: DEFAULT,
  setCreditCardFlip: (value) => {}
});

export const useCreditCardFlip = () => useContext(CreditCardFlipContext);

type Props = {
  children: ReactNode
};

export function CreditCardFlipContextProvider({ children }: Props) {
  const [flip, setCreditCardFlip] = useState(DEFAULT);
  return (
    <CreditCardFlipContext.Provider value={{ flip, setCreditCardFlip }}>
      {children}
    </CreditCardFlipContext.Provider>
  );
};

import React, { Fragment } from 'react';
import tailwind from 'twin.macro';
import styled from 'styled-components/macro';
import { useCreditCardMethod } from 'contexts/credit-card-method';
import { usePaymentMethod } from 'contexts/payment-method';

type CrediCardMethodProps = {
  id: string;
  active: boolean;
  content: string
};

const CrediCardMethodOption = styled.a`
  ${({ active }: { active: boolean }) => active ? tailwind`
    border-indigo-500
    text-indigo-600
  ` : tailwind`
    border-transparent
    text-gray-500
    hover:text-gray-700
    hover:border-gray-300
  `}

  ${tailwind`
    whitespace-nowrap
    py-4
    px-1
    border-b-2
    font-medium
    text-sm
  `}
`;

const MobileContainer = styled.div`
  ${tailwind`
    sm:hidden
  `}

  label {
    ${tailwind`
      sr-only
    `}
  }

  select {
    ${tailwind`
      block
      w-full
      pl-3
      pr-10
      py-2
      text-base
      border-gray-300
      focus:outline-none
      focus:ring-indigo-500
      focus:border-indigo-500
      sm:text-sm
      rounded-md
    `}
  }
`;

const PCContainer = styled.div`
  ${tailwind`
    hidden
    sm:block
    mt-8
  `}
`;

const PCInnerContainer = styled.div`
  ${tailwind`
    border-b
    border-gray-200
  `}
`;

const Nav = styled.nav`
  ${tailwind`
    -mb-px
    flex
    space-x-8
  `}
`;

function CrediCardMethod({ id, active, content }: CrediCardMethodProps) {
  const { setCreditCardMethod } = useCreditCardMethod();
  return (
    <CrediCardMethodOption
      href="#"
      onClick={() => setCreditCardMethod(id)}
      active={active}
    >
      {content}
    </CrediCardMethodOption>
  )
}

const CREDIT_CARD_METHODS = [];
// const CREDIT_CARD_METHODS = [{
//   id: '1-card',
//   content: 'Pagar com 1 cartão'
// }, {
//   id: '2-card',
//   content: 'Pagar com 2 cartões',
// }, {
//   id: 'card-pix',
//   content: 'Pagar com cartão + Pix'
// }];

export default function CrediCardMethods() {
  const { creditCardMethod } = useCreditCardMethod();
  const { paymentMethod } = usePaymentMethod();

  if (paymentMethod !== 'credit' || CREDIT_CARD_METHODS.length === 0) {
    return <Fragment />;
  }

  return (
    <div>
      <MobileContainer>
        <label htmlFor="tabs">Pagar com cartão</label>
        <select name="credit-card-method">
          <option>Pagar com 1 cartão</option>
          <option>Pagar com 2 cartões</option>
          <option selected>Pagar com cartão + Pix</option>
        </select>
      </MobileContainer>
      <PCContainer>
        <PCInnerContainer>
          <Nav>
            {CREDIT_CARD_METHODS.map(({ id, content }) => (
              <CrediCardMethod
                key={id}
                id={id}
                content={content}
                active={creditCardMethod===id}
              />
            ))}
          </Nav>
        </PCInnerContainer>
      </PCContainer>
    </div>
  );
}

import { ThemeProvider as EmotionThemeProvider } from "styled-components";
import { ReactNode, useEffect, useState } from "react";
import themeProvider from "themes/provider";
import { useOrder } from "contexts/order";
import zapay from "themes/zapay";
import { useQuery } from "hooks/query";

type ThemeProps = {
  children: ReactNode;
};

export function ThemeProvider({ children }: ThemeProps) {
  const query = useQuery();
  const { store } = useOrder();
  const themeKey = query.get("t");
  const [theme, setTheme] = useState(zapay);

  const setThemeColorBar = (color?: string) => {
    const link = document.head.querySelector('#theme-color');
    if (link && color) {
      link.setAttribute('content', color);
    }
  }

  const setFavicon = (image?: string) => {
    const link = document.head.querySelector('#favicon');
    if (link && image) {
      link.setAttribute('href', `/favicons/${image}`);
    }
  }

  useEffect(() => {
    const key = themeKey 
      ? themeKey 
      : typeof store?.settings?.theme === 'string' 
        ? store?.settings?.theme 
        : store?.settings?.css;

    const themeDynamic = themeProvider(key);

    setTheme((state) => {
      if (state.logo === themeDynamic.logo) return state;

      setThemeColorBar(store?.settings?.primary || themeDynamic.colors.primary);
      setFavicon(store?.settings?.head?.favicon || themeDynamic.head?.favicon);

      return {
        ...themeDynamic,
        logo: store?.settings?.logo || themeDynamic.logo,
        whitelabel: !!store?.settings?.logo ? true : themeDynamic.whitelabel,
        cssLogos: store?.settings?.cssCheckoutLogos || themeDynamic.cssLogos,
        colors: {
          primary: store?.settings?.primary || themeDynamic.colors.primary,
          secondary: store?.settings?.secondary || themeDynamic.colors.secondary,
        },
      };
    });
  }, [store, themeKey]);

  return <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>;
}

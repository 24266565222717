// @ts-nocheck

import React, { Fragment, useState } from "react";
import tailwind from "twin.macro";
import styled from "styled-components/macro";
import { useQuery } from "hooks/query";
import { useInstallments } from "contexts/installments";

const Container = styled.div`
  ${tailwind`
    bg-yellow-50
    border-l-4
    border-yellow-400
    p-4
    col-span-3
    mt-8
  `}
`;

const IconContainer = styled.div`
  ${tailwind`
    flex-shrink-0
  `}

  svg {
    ${tailwind`
      h-5
      w-5
      text-yellow-400
    `}
  }
`;

const Flex = styled.div`
  ${tailwind`
    flex
  `}
`;

const TextContainer = styled.div`
  ${tailwind`
    ml-3
  `}

  p {
    ${tailwind`
      text-sm
      text-yellow-700
    `}
  }
`;

const DismissIconContainer = styled.div`
  ${tailwind`
    ml-auto
    pl-3
  `}

  div {
    ${tailwind`
      -mx-1.5
      -my-1.5
    `}
  }
  button {
    ${tailwind`
      inline-flex
      bg-yellow-50
      rounded-md
      p-1.5
      text-yellow-500
      hover:bg-yellow-100
      focus:outline-none
      focus:ring-2
      focus:ring-offset-2
      focus:ring-offset-green-50
      focus:ring-green-600
    `}
  }

  svg {
    ${tailwind`
      h-5
      w-5
    `}
  }
`;

export default function MethodAlert() {
  const { dataPlans } = useInstallments();
  const _paymentPlans = [...new Set(dataPlans.plans.map(({ type }) => type))];

  const query = useQuery();
  const paymentOption = query.get("opcao");
  const [visible, setVisible] = useState(
    paymentOption && !_paymentPlans.includes(paymentOption)
  );

  if (!visible) {
    return <Fragment />;
  }

  return (
    <Container>
      <Flex>
        <IconContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </IconContainer>
        <TextContainer>
          <p>A opção de pagamento pré-selecionada não está disponível.</p>
        </TextContainer>
        <DismissIconContainer>
          <div>
            <button onClick={() => setVisible(false)} type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </DismissIconContainer>
      </Flex>
    </Container>
  );
}

import styled from "styled-components/macro";
import tailwind from "twin.macro";
import { transparentize } from "polished";

export const Section = styled.section`
  ${tailwind`
    pb-16
    pt-0
    lg:col-span-7
    lg:mx-auto
    lg:pb-24
    lg:pr-20
    lg:row-start-1
    lg:col-start-1
  `}

  &.mode-bar {
    padding-top: 128px;

    @media (max-width: 600px) {
      padding-top: 82px;
    }
  }
`;

export const Container = styled.section`
  ${tailwind`
    max-w-none
    mx-auto
    lg:max-w-none
    lg:px-0
    
  `}
`;

export const ContainerCart = styled.section`
  ${tailwind`
    mb-2
    mt-4
  `}
`;

export const SubmitContainer = styled.div`
  ${tailwind`
    lg:mt-8
    lg:pt-6
    lg:border-t
    border-gray-200
  `}
`;

export const SubmitButton = styled.button`
  ${tailwind`
    bg-gradient-to-r
    mt-8
    from-pink-800
    to-zapay
    border
    border-transparent
    rounded-3xl
    shadow-sm
    h-11
    flex
    justify-center
    items-center
    py-2
    px-4
    font-semibold
    text-white
    hover:to-indigo-700
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    focus:ring-offset-gray-50
    focus:ring-indigo-500
    min-w-full
    sm:min-w-0
    transition
    hover:scale-110
    rounded-full
  `}
  ${(props) => `
    background: #ff0060;
    &:hover{
      box-shadow: ${transparentize(
        0.6,
        props.theme.colors.secondary
      )} 0px 7px 20px;
    }
  `}
`;

export const SecureCheckout = styled.p`
  ${tailwind`
    flex
    justify-center
    md:justify-start
    text-xs
    font-medium
    text-green-500
    mt-6
    items-center
  `}

  svg {
    ${tailwind`
      w-5
      h-5
      text-green-400
      mr-1.5
    `}
  }
`;

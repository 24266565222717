import { cpf } from "cpf-cnpj-validator";

export const documentMask = (value: string) => {
  const digits = value?.replace(/[^\d]/g, "");
  if ((digits?.length === 11 && cpf.isValid(digits)) || digits?.length < 11) {
    return "###.###.###-##";
  } else {
    return "##.###.###/####-##";
  }
};

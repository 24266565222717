import { Theme } from "./types";

const bb: Theme = {
  title: 'bb',
  coupon: false,
  whitelabel: true,
  displayFees: true,
  orderOnSameHost: true,
  logo: "bb-receita-federal.png",
  head: {
    favicon: 'bb-favicon.png',
  },
  header: {
    modBar: true,
    notZapay: true,
  },
  footer: {
    logo: "bb-pay.png",
    css: 'svg.zapay{width:64px;height:24px;margin-top:4px;}',
  },
  colors: {
    primary: "#142341",
    secondary: "#142341",
  }
};

export default bb;

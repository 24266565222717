import styled from "styled-components/macro";
import { useTheme } from "styled-components";

export const Image = styled.img.attrs(({ theme }) => {
    return {
      src: require(`assets/images/${theme.footer.logo}`).default,
    };
  })`
    width: auto;
    height: 36px;
  `;
  
  export function PartnerLogo() {
    const { footer } = useTheme();
  
    if (!footer) {
      return null;
    }
  
    return (
      <div className="logo-partner">
        <Image alt="Logo" />
      </div>
    );
  }
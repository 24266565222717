// @ts-nocheck

import { useQuery } from "hooks/query";
import React, {
  ReactNode,
  useContext,
  createContext,
  useState,
  useEffect,
} from "react";
import { useInstallments } from "./installments";

type PaymentMethodContextType = {
  paymentMethod: string;
  setPaymentMethod: (value: string) => void;
};

const DEFAULT = "credit";

const PaymentMethodContext = createContext<PaymentMethodContextType>({
  paymentMethod: DEFAULT,
  setPaymentMethod: (value) => {},
});

export const usePaymentMethod = () => useContext(PaymentMethodContext);

type Props = {
  children: ReactNode;
};

export function PaymentMethodContextProvider({ children }: Props) {
  const query = useQuery();
  const { dataPlans } = useInstallments();
  const [paymentMethod, setPaymentMethod] = useState(DEFAULT);

  useEffect(() => {
    const paymentOptions = [...new Set(dataPlans.plans.map(({ type }) => type))];
    const paymentOption = query.get("opcao");

    if (
      ["credit", "pix", "ticketlog"].includes(paymentOption) &&
      paymentOptions.includes(paymentOption)
    ) {
      setPaymentMethod(paymentOption);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PaymentMethodContext.Provider value={{ paymentMethod, setPaymentMethod }}>
      {children}
    </PaymentMethodContext.Provider>
  );
}

import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
import tailwind from 'twin.macro'
import { useTheme } from 'styled-components'
import { usePaymentMethod } from 'contexts/payment-method'
import { useOrder } from 'contexts/order'
import TotalAmount from 'components/Amount/Total'
import CardValidator from 'card-validator'
import { CARD_FLAGS } from 'components/Payment/CreditCard/card-infos/constants'
import { useHistory, useParams } from 'react-router'
import ScrollToTopOnMount from 'utils/scrollTop'

const Icon = styled.div`
   ${tailwind`
    mx-auto
    flex
  
    items-center
    justify-center
  `}

   svg {
      ${tailwind`
      animate-pulse
      
    
      text-green-600
    `}
   }
`
const Heading = styled.h3`
   ${tailwind`
    text-lg
    leading-6
    font-medium
    text-gray-900
    mb-3
    pt-2
    leading-9
  `}
`

const HeadingContainer = styled.div`
   ${tailwind`
    flex
    items-center
    justify-center
    
    
    `}
`

const MainHeading = styled.h3`
   ${tailwind`
      text-2xl	
      font-bold
      text-gray-900
      mb-4
      pb-4
    `}
`

const HeadingList = styled.h3`
   ${tailwind`
    text-lg
    leading-6
    text-gray-900
    flex
    items-start
    mb-3
    
  `}
`
const Content = styled.div`
   ${tailwind`
    mt-3
  `}

   p {
      ${tailwind`
      text-sm
      text-gray-500
    `}
   }
`

const ItemList = styled.li`
   ${tailwind`
    flex
    flex-row
    items-center 
    mb-2
    space-x-2
  `}
   align-items: flex-start;
`

const CardContainer = styled.div`
   ${tailwind`
    flex
    flex-row
    items-center 
    mb-2
    space-x-2

  `}
   align-items: flex-start;

   .flagdiv {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 50%;
    background-color: rgba(247, 247, 247, 1);
    display: flex;
    padding: 5px;
   }

   .flag {
      max-width: 100%;
      height: auto;
    }
`

const ContentList = styled.div`
   ${tailwind`
    mt-3
   
  `}

   p {
      ${tailwind`
      text-sm
      text-gray-500
    `}
   }
   ${ItemList} {
      align-items: flex-start;
   }
`

const Container = styled.div`
   ${tailwind`
    mt-3
    text-center
    sm:mt-5
    py-5
  `}
`

const ButtonContainer = styled.div`
   ${tailwind`
    flex
    pb-6
    flex-col
    px-6
  `}
`

const Link = styled.a`
   ${tailwind`
    inline-flex
    justify-center
    rounded-md
    border
    px-4
    py-2
    sm:text-sm
  `}
`

const PinkButton = styled(Link)`
   ${tailwind`
    bg-pink-500
    text-white
    hover:bg-pink-600
    items-center
    focus:ring-pink-500
    rounded-3xl	
    font-medium
    focus:outline-none
    mb-6
    h-12
    text-center
  `}
   background-color: rgba(255, 0, 96, 1);
`

const WhiteButton = styled(Link)`
   ${tailwind`
    bg-white
    h-12
    font-medium
    hover:bg-gray-100
    focus:ring-pink-500
    rounded-3xl	
    items-center
    focus:outline-none
  `}
   color: rgba(255, 0, 96, 1);
   border: 1px solid rgba(255, 0, 96);
`

const OrderDetails = styled.div`
   ${tailwind`
    mt-3
    mx-6
    mb-3
    py-4

  `}

   dl {
      ${tailwind`
      grid grid-cols-2 gap-x-4
    `}
   }

   dt,
   dd {
      ${tailwind`
      py-1
    `}
   }

   dt {
      ${tailwind`
      text-sm font-medium text-gray-500
    `}
   }

   dd {
      ${tailwind`
      text-sm 
      font-bold
      text-gray-900
    `}
   }
`

const DetailsItem = styled.div`
   ${tailwind`
    flex
    items-center
    justify-between
    py-2

  `}
`

const DetailsItemF = styled.div`
   ${tailwind`
    flex
    items-center
    justify-between
    pt-2

  `}
`

const HeadingOrder = styled.h3`
   ${tailwind`
    flex
    items-start
    text-lg
    leading-6
    font-medium
    text-gray-900
  `}
`

const List = styled.ul`
   ${tailwind`
    px-6
    flex 
    flex-col
    items-start 
    text-left
    list-disc
  `}
`

const TitleList = styled.h3`
   ${tailwind`
    font-medium

  `}
`

const MiniIcon = styled.div`
   ${tailwind`
    w-3
    items-center
    h-12
    pt-0.5
  `}
`;

const MiniIcon1 = styled.div`
  ${tailwind`
    pt-0.5
    flex
    items-center
    
  `}
`

export default function Denied({data, hide}) {
  const history = useHistory();
  const { id } = useParams<{ id: string }>()
  
  const parcelsAmount = TotalAmount("parcels")
  const totalAmount = TotalAmount("total")
  const totalTax = TotalAmount("tax")
  const totalService = TotalAmount("")
  const initialAmount = TotalAmount("initial").toString()
  

  const cardType = CardValidator.number(
    data?.card?.number.replace(/[^\d]/g, "")
  );
  const brand = cardType.card?.type;
  const flag = CARD_FLAGS[brand]

  const handleClick = () => {
    hide()
    history.replace(`/${id}/simulacao?total=${initialAmount}`)
  }

   return (
      <>
         <ScrollToTopOnMount />
         <Container>
            <Icon>
               <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.1" cx="48" cy="48" r="48" fill="#D90429" />
                  <path
                     d="M47.9995 43V49.6667M47.9995 56.3334H48.0162M45.6917 34.4862L31.9836 58.1639C31.2232 59.4772 30.8431 60.1339 30.8992 60.6728C30.9482 61.1429 31.1945 61.5701 31.5768 61.848C32.0151 62.1667 32.7738 62.1667 34.2914 62.1667H61.7077C63.2252 62.1667 63.984 62.1667 64.4222 61.848C64.8045 61.5701 65.0508 61.1429 65.0998 60.6728C65.156 60.1339 64.7758 59.4772 64.0155 58.1639L50.3073 34.4862C49.5497 33.1776 49.1709 32.5233 48.6767 32.3036C48.2456 32.1119 47.7534 32.1119 47.3223 32.3036C46.8281 32.5233 46.4493 33.1776 45.6917 34.4862Z"
                     stroke="#D90429"
                     strokeWidth="3.5" // Adjust the stroke width as needed
                     strokeLinecap="round"
                     strokeLinejoin="round"
                  />
               </svg>
            </Icon>
            <Container>
               <HeadingContainer>
                  <MainHeading>Seu pagamento não foi aprovado </MainHeading>
               </HeadingContainer>
               <hr></hr>
               <ContentList>
                  <List>
                     <Heading>Verifique as possíveis causas:</Heading>
                     <ItemList>
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                              stroke="#A3A3A3"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                        </svg>
                        <p>Dados do cartão digitados incorretamente;</p>
                     </ItemList>

                     <ItemList>
                        <MiniIcon>
                           <svg
                              width="12"
                              height="12"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                                 stroke="#A3A3A3"
                                 stroke-width="1.3"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              />
                           </svg>
                        </MiniIcon>
                        <p>Funcionalidade de compras online está desativada no app do seu banco;</p>
                     </ItemList>

                     <ItemList>
          <MiniIcon1>
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                              stroke="#A3A3A3"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                        </svg>
          </MiniIcon1>
                        <p>Cartão bloqueado;</p>
                     </ItemList>

                     <ItemList>
          <MiniIcon1>
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                              stroke="#A3A3A3"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                        </svg>
          </MiniIcon1>
                        <p>Cartão com a data de validade expirada;</p>
                     </ItemList>

                     <ItemList>
          <MiniIcon1>
                        <svg width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                              stroke="#A3A3A3"
                              stroke-width="1.3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                           />
                        </svg>
          </MiniIcon1>
                        <p>Cartão com restrição ou limite indisponível;</p>
                     </ItemList>

                     <ItemList>
                        <MiniIcon>
                           <svg
                              width="12"
                              height="12"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                                 stroke="#A3A3A3"
                                 stroke-width="1.3"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              />
                           </svg>
                        </MiniIcon>
                        <p>Limite de parcelamento não autorizado pela operadora do cartão;</p>
                     </ItemList>

                     <ItemList>
                        <MiniIcon>
                           <svg
                              width="12"
                              height="12"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M7.99992 10.6666V7.99992M7.99992 5.33325H8.00659M14.6666 7.99992C14.6666 11.6818 11.6818 14.6666 7.99992 14.6666C4.31802 14.6666 1.33325 11.6818 1.33325 7.99992C1.33325 4.31802 4.31802 1.33325 7.99992 1.33325C11.6818 1.33325 14.6666 4.31802 14.6666 7.99992Z"
                                 stroke="#A3A3A3"
                                 stroke-width="1.3"
                                 stroke-linecap="round"
                                 stroke-linejoin="round"
                              />
                           </svg>
                        </MiniIcon>
                        <p>Se necessário, contate a central de atendimento do seu cartão.</p>
                     </ItemList>
                  </List>
               </ContentList>
            </Container>

            <ButtonContainer>
               <PinkButton onClick={handleClick} className="btn btn-primary">
                  Tentar novamente
               </PinkButton>

               <WhiteButton href="https://link.usezapay.com.br/rubEPc" target="_blank" className="btn btn-secondary">
                  Precisa de ajuda?
               </WhiteButton>
            </ButtonContainer>

            <hr></hr>

            <OrderDetails>
               <HeadingOrder>Detalhes do pedido</HeadingOrder>

               <DetailsItem>
                  <dt>Serviço mecânico</dt>
                  <dt>{totalService}</dt>
               </DetailsItem>
               <hr></hr>
               <DetailsItem>
                  <dt>Taxa de cartão</dt>
                  <dt>{totalTax}</dt>
               </DetailsItem>

               <hr></hr>
               <DetailsItemF>
                  <dd>Total</dd>
                  <dd>{totalAmount}</dd>
               </DetailsItemF>
            </OrderDetails>

            <hr></hr>
            <Content>
               <List>
                  <Heading>Forma de pagamento</Heading>
                  <CardContainer>
                    <div className="flagdiv">
                      {flag ? (<img className="flag" src={flag.src} alt={flag.alt} />) : null}
                    </div>

                     <div>
                        <TitleList>{brand?.toUpperCase()} •••• {data?.card?.number?.slice(-4) || ''}</TitleList>
                        <p>{parcelsAmount}</p>
                     </div>
                     <br />
                  </CardContainer>
               </List>
            </Content>

            <hr/>
            <Content>
               <List>
                  <Heading>Dados do pagador</Heading>

                  <TitleList>{data?.card?.holder}</TitleList>
                  <p>CPF: {data?.card?.document}</p>
               </List>
            </Content>
         </Container>
      </>
   )
}

import creditCardType from 'credit-card-type';

export const KEYS = {
  PLAN_TYPES: {
    PIX: 'pix',
    CREDIT: 'credit',
    VOUCHER: 'voucher',
    PIX_CARD: 'pix_card',
    BANK_SLIP: 'bankslip',
    TWO_CARDS: 'two_cards',
    TICKET_LOG: 'ticketlog',
  },
  CARDS_PREFIXES: {
    TICKET_LOG: ['63', '605680'],
    GOOD_CARD: ['605674', '605681'],
  },
}

export const getNationalCardIssuer = (
  type?: string,
  context?: string,
  number?: string,
) => {
  const options: Record<string, () => string | undefined> = {
    discover: () => 'elo',
    'diners-club': () => 'elo',
    maestro: () => {
      if (context === KEYS.PLAN_TYPES.CREDIT) {
        return 'hiper';
      }

      const prefix = number?.slice(0, 6) || '';

      if (KEYS.CARDS_PREFIXES.GOOD_CARD.includes(prefix)) {
        return 'goodcard';
      }

      return 'ticket';
    },
  };

  return type ? options[type]?.() || type : type;
};

export const Format = {
  number: (value?: string) => {
    if (!value) return '';

    const digits = value.replace(/[^\d]/g, '');
    const cards = creditCardType(digits);

    let gaps = [4, 8, 12];
    let maxLength = 16;

    if (cards && cards.length > 0) {
      const card = cards[0];
      gaps = card.gaps;
      maxLength = Math.max(...card.lengths);
    }

    const card = digits.substring(0, maxLength).split('');
    let splitted = 0;

    gaps.forEach((gap) => {
      card.splice(gap + splitted, 0, ' ');
      splitted++;
    });

    return card.join('').trim();
  },
};

export const Normalize = {
  number: (value?: string) => {
    return value
      ?.replace(/ /g, '')
      ?.replace(/.(?=.{4})/g, '•')
      ?.match(/.{1,4}/g)
      ?.join(' ');
  },

  validity: (value?: string) => {
    if (!value) return '';

    const digits = value.replace(/[^\d]/g, '').substring(0, 4);
    return digits.replace(/(\d{2})(\d)/, '$1/$2');
  },

  cvv: (cvv: string, number?: string) => {
    if (!cvv || !number) return '';

    let maxLength = 3;

    if (number.length > 0) {
      const digits = number.replace(/[^\d]/g, '');
      const cards = creditCardType(digits);

      if (cards.length > 0) {
        maxLength = cards[0].code.size;
      }
    }

    return cvv.replace(/[^\d]/g, '').substring(0, maxLength);
  },
};

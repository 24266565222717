export const ZapayLogo = () => {
  return (
    <svg
      height="36"
      width="108.001"
      className="zapay"
      viewBox="0 0 108.001 36"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>{`.cls-2{fill:#FE0060}`}</style>
      </defs>
      <g id="Group_778" transform="translate(-277.999 -32)">
        <path
          id="Rectangle_797"
          fill="none"
          d="M0 0H108V36H0z"
          transform="translate(278 32)"
        />
        <g id="Group_531" transform="translate(-1493.506 407.803)">
          <path
            id="Path_584"
            d="M2001.763-357.862c.491-1.09.938-2.078 1.382-3.068l3.948-8.816a2.8 2.8 0 0 1 2.709-1.723 2.76 2.76 0 0 1 2.524 1.967 2.862 2.862 0 0 1-.225 2.063q-3.071 6.564-6.122 13.138l-4.1 8.8a2.791 2.791 0 0 1-4.062 1.282 2.648 2.648 0 0 1-1.032-3.369c.557-1.267 1.144-2.523 1.745-3.771a.842.842 0 0 0-.008-.816q-3.572-7.621-7.12-15.252a2.7 2.7 0 0 1 .969-3.638 2.77 2.77 0 0 1 4 1.248c1.043 2.264 2.055 4.542 3.081 6.815z"
            className="cls-2"
            transform="translate(-133.925 -0.203)"
          />
          <path
            id="Path_585"
            d="M1779.67-356.36c2.62 0 5.112-.005 7.6 0a2.517 2.517 0 0 1 2.6 1.939 2.472 2.472 0 0 1-2.569 2.992c-1.944.021-3.889.005-5.834.005-2.351 0-4.7-.005-7.053 0a2.663 2.663 0 0 1-2.648-1.552 2.57 2.57 0 0 1 .578-3.012q4.552-4.924 9.1-9.849c.094-.1.187-.206.295-.325-.148-.161-.334-.084-.492-.084-2.157-.006-4.313.007-6.47-.009a2.481 2.481 0 0 1-2.449-1.786 2.427 2.427 0 0 1 .977-2.7 2.606 2.606 0 0 1 1.5-.448c4.1 0 8.2-.022 12.3.01a2.637 2.637 0 0 1 2.859 2.734 2.875 2.875 0 0 1-.85 1.848q-4.558 4.918-9.1 9.846c-.093.106-.18.207-.344.391z"
            className="cls-2"
            transform="translate(0 -0.379)"
          />
          <path
            id="Path_586"
            d="M1899.508-368.111a8.551 8.551 0 0 0-12.66-1.56c-.076.064-.138.16-.266.145-.024-.062-.048-.127-.074-.191a2.758 2.758 0 0 0-3.159-1.765 2.689 2.689 0 0 0-2.219 2.765q-.007 10.865 0 21.731a2.72 2.72 0 0 0 2.769 2.8 2.733 2.733 0 0 0 2.754-2.815c.008-1.245 0-2.489 0-3.735v-3.079c.16.129.252.2.342.274a8.516 8.516 0 0 0 11.446-.45c3.59-3.477 4.076-9.923 1.067-14.12zm-4.414 9.325a4.236 4.236 0 0 1-4.146 2.29 4.476 4.476 0 0 1-4.078-3.058 5.354 5.354 0 0 1-.374-1.963 5.714 5.714 0 0 1 .855-3.224 4.476 4.476 0 0 1 7.621-.012 5.536 5.536 0 0 1 .122 5.967z"
            className="cls-2"
            transform="translate(-66.862 -0.01)"
          />
          <path
            id="Path_587"
            d="M1842.438-359.407c0-3.111.007-6.223 0-9.334a2.712 2.712 0 0 0-2.249-2.749 2.743 2.743 0 0 0-3.142 1.786c-.024.06-.055.117-.1.213l-.34-.278a8.534 8.534 0 0 0-11.888.823 11.431 11.431 0 0 0-1.186 12.916 8.59 8.59 0 0 0 13.249 2.294c.049-.041.092-.114.219-.047a2.78 2.78 0 0 0 .48.927 2.789 2.789 0 0 0 3.04.912 2.69 2.69 0 0 0 1.916-2.589c.016-1.624.001-3.249.001-4.874zm-5.374-2.441a5.707 5.707 0 0 1-1.026 3.478 4.482 4.482 0 0 1-7.08.3 5.777 5.777 0 0 1-.01-7.2 4.3 4.3 0 0 1 4.366-1.5 4.576 4.576 0 0 1 3.51 3.391 4.8 4.8 0 0 1 .24 1.531z"
            className="cls-2"
            transform="translate(-30.842 -0.004)"
          />
          <path
            id="Path_588"
            d="M1957.035-368.936a2.524 2.524 0 0 0-1.56-2.345 2.813 2.813 0 0 0-3.905 1.8c-.137-.113-.23-.19-.322-.268a8.513 8.513 0 0 0-11.483.358c-3.5 3.365-4.1 9.592-1.337 13.808a8.564 8.564 0 0 0 12.934 1.871 2.2 2.2 0 0 1 .216-.142c.077.183.137.345.212.5a2.786 2.786 0 0 0 3.136 1.471 2.679 2.679 0 0 0 2.114-2.538q.017-7.257-.005-14.515zm-6.48 10.7a4.482 4.482 0 0 1-7.181-.091 5.813 5.813 0 0 1-.024-6.671 4.485 4.485 0 0 1 7.336.05 5.452 5.452 0 0 1 .99 3.274 5.38 5.38 0 0 1-1.121 3.437z"
            className="cls-2"
            transform="translate(-100.754)"
          />
        </g>
      </g>
    </svg>
  );
};

export default ZapayLogo;

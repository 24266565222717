import { Theme } from "./types";

const portoSeguro: Theme = {
  coupon: false,
  whitelabel: true,
  displayFees: true,
  logo: "porto-seguro.png",
  colors: {
    primary: "#36BEFF",
    secondary: "#0046c0",
  },
};

export default portoSeguro;

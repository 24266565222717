import React, { ReactNode } from "react";
import {
  Centerize,
  Container,
  ModalContainer,
  ModalDialog,
  Overlay,
} from "./styles";

type ModalProps = {
  children: ReactNode;
  hidden?: boolean;
};

export default function Modal({ children, hidden }: ModalProps) {
  return (
    <Container role="dialog" aria-modal="true" hidden={hidden}>
      <ModalContainer>
        <Overlay />
        <Centerize>&#8203;</Centerize>
        <ModalDialog>{children}</ModalDialog>
      </ModalContainer>
    </Container>
  );
}

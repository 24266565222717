import tailwind from "twin.macro";
import styled from "styled-components/macro";
import { usePaymentMethod } from "contexts/payment-method";
import { useOrder } from "contexts/order";
import { useCoupon } from "contexts/coupon";
import { useInstallments } from "contexts/installments";
import { Theme } from "themes/types";
import { useEffect } from "react";

type PaymentMethodContainerProps = {
  active: boolean;
  theme: Theme;
};

const PaymentMethodsContainer = styled.div`
  ${tailwind`
    mt-4
    flex
    flex-row
    gap-x-2
    lg:gap-x-4
  `}
`;

const PaymentMethodContainer = styled.div`
  ${({ active, theme }: PaymentMethodContainerProps) =>
    active
      ? `
      color: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
      box-shadow: 0 0 1px 1px ${theme.colors.primary};
      `
      : tailwind`
    text-gray-700
    border-gray-300
  `}

  ${tailwind`
    relative
    bg-white
    border
    rounded-md
    py-2
    px-2
    flex
    cursor-pointer
    focus:outline-none
    items-center
  `}

  svg {
    ${tailwind`
      h-5
      w-5
      mr-2
    `}
  }

  span {
    ${tailwind`
      text-xs
      font-medium
      text-left
    `}
  }
`;

const paymentMethods = [
  {
    id: "credit",
    title: "Cartão de Crédito",
  },
  {
    id: "pix",
    title: "Pix",
  },
  // {
  //   id: 'bankslip',
  //   title: 'Boleto Parcelado'
  // },
  // {
  //   id: 'apple-pay',
  //   title: 'Apple Pay'
  // },
  {
    id: "ticketlog",
    title: "TicketLog",
  },
];

type PaymentMethodProps = {
  id: string;
  active: boolean;
  title: string;
};

const DEFAULT_INSTALLMENTS_BY_PAYMENT_METHOD = {
  credit: 12,
  ticketlog: 1,
};

function PaymentMethod({ id, active, title }: PaymentMethodProps) {
  const { setPaymentMethod } = usePaymentMethod();
  const { setSelected } = useInstallments();
  const { setCoupon } = useCoupon();

  function handleClick() {
    setPaymentMethod(id);
    if (id !== "credit") {
      setCoupon({
        validated: false,
      });
    }
    if (id in DEFAULT_INSTALLMENTS_BY_PAYMENT_METHOD) {
      setSelected(DEFAULT_INSTALLMENTS_BY_PAYMENT_METHOD[id]);
    }
  }

  return (
    <PaymentMethodContainer
      onClick={() => handleClick()}
      key={id}
      active={active}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
        />
      </svg>
      <span>{title}</span>
    </PaymentMethodContainer>
  );
}

export default function PaymentMethods() {
  const { store } = useOrder();
  const { dataPlans } = useInstallments();
  const { paymentMethod, setPaymentMethod } = usePaymentMethod();
  const _paymentPlans = dataPlans.plans.map(({ type }) => type).filter(item => {
    const toDisable = store?.settings?.disablePaymentsInCheckout || [];
    if (!toDisable || Array.from(toDisable).length === 0) return true;

    return  !Array.from(toDisable).includes(item);
  });

  const availablePaymentMethods = paymentMethods.filter(({ id }) =>
    _paymentPlans.includes(id)
  );

  useEffect(() => {
    if (!_paymentPlans.includes(paymentMethod) && _paymentPlans.length > 0) {
      setPaymentMethod(_paymentPlans[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  if (availablePaymentMethods.length < 2) {
    return <></>;
  }

  return (
    <PaymentMethodsContainer>
      {availablePaymentMethods.map(({ id, title }) => (
        <PaymentMethod
          key={id}
          id={id}
          title={title}
          active={id === paymentMethod}
        />
      ))}
    </PaymentMethodsContainer>
  );
}

import styled from 'styled-components/macro'
import tailwind from 'twin.macro'
import { useOrder } from 'contexts/order'

import TotalAmount from 'components/Amount/Total'
import cardValidator from 'card-validator'
import { CARD_FLAGS } from 'components/Payment/CreditCard/card-infos/constants'

// const Icon = styled.div`
//    ${tailwind`
//     mx-auto
//     flex
//     items-center
//     justify-center
//   `}

//    svg {
//       ${tailwind`
//       animate-pulse
//       h-28 
//       w-28
//       text-green-600
//     `}
//    }
// `
const Heading = styled.h3`
   ${tailwind`
    text-lg
    leading-6
    font-medium
    text-gray-900
    mb-3
    pt-2
    leading-9
  `}
`

const Link = styled.a`
   ${tailwind`
    inline-flex
    justify-center
    rounded-md
    border
    px-4
    py-2
    sm:text-sm
  `}
`

// const WhiteButton = styled(Link)`
//    ${tailwind`
//     bg-white
//     h-12
//     font-medium
//     hover:bg-gray-100
//     focus:ring-pink-500
//     rounded-3xl	
//     items-center
//     space-x-2
//     focus:outline-none
//   `}
//    color: rgba(255, 0, 96, 1);
//    border: 1px solid rgba(255, 0, 96);
// `

const HeadingContainer = styled.div`
   ${tailwind`
    flex
    flex-col
    items-start
    pb-4
    pt-7
    `}

   p {
      ${tailwind`
      pb-2
      font-medium
  
    `}
   }
`

// const MainHeading = styled.h3`
//    ${tailwind`
    
//     text-3xl	
//     leading-6
//     font-semibold
//     text-gray-900
//     mb-4
//     pb-4
    
//   `}
// `

// const HeadingList = styled.h3`
//    ${tailwind`
//     text-lg
//     leading-6
//     text-gray-900
//     flex
//     items-start
//     mb-3
    
//   `}
// `
const Content = styled.div`
   ${tailwind`
    mt-3
    mb-5
  `}

   p {
      ${tailwind`
      text-sm
      text-gray-500
    `}
   }
`

const ItemList = styled.div`
   ${tailwind`
    flex
    flex-row
    items-center 
    mb-2
    space-x-2

  `}
   align-items: flex-start;
`

const CardContainer = styled.div`
   ${tailwind`
    flex
    flex-row
    items-center 
    mb-2
    space-x-2

  `}
   align-items: flex-start;

   .flagdiv {
      width: 48px;
      height: 48px;
      overflow: hidden;
      border-radius: 50%;
      background-color: rgba(247, 247, 247, 1);
      display: flex;
      padding: 5px;
   }

   .flag {
      max-width: 100%;
      height: auto;
   }
`

// const ContentList = styled.div`
//    ${tailwind`
//     mt-3
 
   
//   `}

//    p {
//       ${tailwind`
//       text-sm
//       text-gray-500
//     `}
//    }
//    ${ItemList} {
//       align-items: flex-start; /* Reset alignment for ItemList within ContentList */
//    }
// `

const Container = styled.div`
   ${tailwind`
    text-left
  `}
`

// const ButtonContainer = styled.div`
//    ${tailwind`
//     flex
//     pb-6
//     flex-col
//     px-6
//     my-8
//   `}
// `

const OrderDetails = styled.div`
   ${tailwind`
    mt-3
    py-4
  `}

   dl {
      ${tailwind`
      grid 
      grid-cols-2 
      gap-x-4
     
    `}
   }

   dt,
   dd {
      ${tailwind`
      py-1
    `}
   }

   dt {
      ${tailwind`
      text-sm font-medium text-gray-500
    `}
   }

   dd {
      ${tailwind`
      text-sm 
      font-bold
      text-gray-900
    `}
   }
`

const DetailsItem = styled.div`
   ${tailwind`
    flex
    items-center
    justify-between
    py-2

  `}
`

const DetailsItemF = styled.div`
   ${tailwind`
    flex
    items-center
    justify-between
    pt-2

  `}
`

const HeadingOrder = styled.h3`
   ${tailwind`
    flex
    items-start
    text-lg
    leading-6
    font-medium
    text-gray-900
    mb-3
  `}
`

const List = styled.ul`
   ${tailwind`
    flex 
    flex-col
    items-start 
    text-left
    
  `}
`

const TitleList = styled.h3`
   ${tailwind`
    font-medium

  `}
`

export default function Receipt({ data }) {
   const { protocol } = useOrder()

   const date = new Date().toLocaleDateString('pt-BR', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: 'America/Sao_Paulo',
   })

   const parcelsAmount = TotalAmount('parcels')
   const totalAmount = TotalAmount('total')

   const cardType = cardValidator.number(data?.card?.number.replace(/[^\d]/g, ''))
   const brand = cardType.card?.type
   const flag = CARD_FLAGS[brand]

   return (
      <Container>
         <HeadingContainer>
            <p> Comprovante de pagamento </p>
         </HeadingContainer>
         <hr />
         <OrderDetails>
            <HeadingOrder>Detalhes do pedido</HeadingOrder>

            <DetailsItem>
               <dt>{date}</dt>
            </DetailsItem>
            <hr />
            <DetailsItem>
               <dd>Protocolo</dd>
               <dd>{protocol}</dd>
            </DetailsItem>

            <hr></hr>
            <DetailsItemF>
               <dd>Total</dd>
               <dd>{totalAmount}</dd>
            </DetailsItemF>
         </OrderDetails>
         <hr></hr>

         <Content>
            <List>
               <Heading>Forma de pagamento</Heading>
               <CardContainer>
                  <div className="flagdiv">{flag ? <img className="flag" src={flag.src} alt={flag.alt} /> : null}</div>

                  <div>
                     <TitleList>
                        {brand?.toUpperCase()} •••• {data?.card?.number?.slice(-4) || ''}
                     </TitleList>
                     <p>{parcelsAmount}</p>
                  </div>
                  <br />
               </CardContainer>
            </List>
         </Content>

         <hr></hr>

         <Content>
            <List>
               <Heading>Dados do pagador</Heading>

               <TitleList>{data?.card?.holder}</TitleList>
               <p>CPF: {data?.card?.document}</p>
            </List>
         </Content>

         <hr />

         <hr />

         {/* <ButtonContainer>
            <WhiteButton href="#" className="btn btn-secondary">
               <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M18 10V13.5C18 14.9001 18 15.6002 17.7275 16.135C17.4878 16.6054 17.1054 16.9878 16.635 17.2275C16.1002 17.5 15.4001 17.5 14 17.5H7C5.59987 17.5 4.8998 17.5 4.36502 17.2275C3.89462 16.9878 3.51217 16.6054 3.27248 16.135C3 15.6002 3 14.9001 3 13.5V10M13.8333 5.83333L10.5 2.5M10.5 2.5L7.16667 5.83333M10.5 2.5V12.5"
                     stroke="#FF0060"
                     stroke-width="2"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                  />
               </svg>
               <p>Compartilhar</p>
            </WhiteButton>
         </ButtonContainer> */}
      </Container>
   )
}

import { useOrder } from 'contexts/order';
import React, { Fragment, useEffect } from 'react';


export default function BraspagFingerPrint() {

  const { protocol } = useOrder();

  useEffect(() => {
    const script = document.createElement("script");
    const environment = process.env.REACT_APP_ENV;

    let orgId;

    if (environment === "production") {
      orgId = "k8vif92e";
    } else {
      orgId = "1snn5n9w";
    }

    const merchantId = "braspag_zapay";
    const sessionId = protocol;
    const src = `https://h.online-metrix.net/fp/tags.js?org_id=${orgId}&session_id=${merchantId}${sessionId}`;
    script.src = src;
    document.body.appendChild(script);
  }, [protocol]);

  return <Fragment />;
}

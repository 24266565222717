
import { cpf, cnpj } from 'cpf-cnpj-validator';

const CPF_LENGTH = 11;
const CNPJ_LENGTH = 14;

const validateCpf = (value: string) => {
  if (value.length < CPF_LENGTH) {
    return 'CPF/CNPJ incompleto.';
  } else if (!cpf.isValid(value)) {
    return 'CPF/CNPJ inválido.';
  }
};

const validateCnpj = (value: string) => {
  if (value.length < CNPJ_LENGTH) {
    return 'CNPJ incompleto.';
  } else if (!cnpj.isValid(value)) {
    return 'CNPJ inválido.';
  }
};

export const validateDocument = (value: string) => {
  const digits = value.replace(/[^\d]/g, '');
  if (digits.length <= CPF_LENGTH) {
    return validateCpf(digits);
  }
  return validateCnpj(digits);
};

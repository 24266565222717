import { Theme } from "./types";

const bancopan: Theme = {
  coupon: false,
  whitelabel: true,
  displayFees: true,
  logo: "bancopan.png",
  colors: {
    primary: "#07a5ec",
    secondary: "#07b2fd",
  }
};

export default bancopan;

import styled from "styled-components/macro";
import { useTheme } from "styled-components";
import tailwind from "twin.macro";

export const Image = styled.img.attrs(({ theme }) => {
  return {
    src: require(`assets/images/${theme.logo}`).default,
  };
})`
  ${tailwind`
    h-8
    w-auto
    sm:h-10
  `}
`;

export default function WhiteLabelLogo() {
  const { whitelabel } = useTheme();

  if (!whitelabel) {
    return null;
  }

  return (
    <div className="logo-partner">
      <Image alt="Logo" />
    </div>
  );
}

declare global {
  interface Window {
    purchaseId?: any;
    purchaseAmount?: any;
    gtag?: any;
  }
}
export function googleAnalyticsTrackConvertion(
  protocol: string,
  revenue: number
) {
  window.purchaseId = protocol;
  window.purchaseAmount = revenue;

  gtagTrackPurchase(protocol, revenue);

  // window.ga("ecommerce:addTransaction", {
  //   id: protocol,
  //   revenue,
  //   origin: "checkout",
  // });

  // window.ga("ecommerce:send");
}

export function gtagTrackPurchase(protocol: string, value: number) {
  window.gtag("event", "purchase", {
    transaction_id: protocol,
    affiliation: "Checkout Page",
    value,
    currency: "BRL",
    tax: 0,
    shipping: 0,
    items: [],
  });
}

import styled from 'styled-components';
import tailwind from "twin.macro";
import { Theme } from "themes/types";

export const Wrapper = styled.div`
  bottom: 0;
  width: 100%;
  position: relative;
  background-color: white;
  border-top: 1px solid #BCCFE5;

  ${tailwind`
    lg:grid
    lg:grid-cols-10
    xl:pl-28
    xl:mr-12
    lg:px-8
  `}

  div.w-container {
    gap: 2rem;
    width: 100%;
    display: flex;
    padding: 1rem 0;
    max-width: 996px;
    align-items: center;

    ${tailwind`
        lg:col-span-7
        lg:mx-auto
        lg:pr-20
        lg:row-start-1
        lg:col-start-1
    `}

    @media (max-width: 600px) {
      padding: 1rem;
    }

    svg {
        width: 94px;
    }

    ${({ theme }: { theme: Theme }) => !!theme?.footer?.css && `
      ${theme?.footer?.css};
    `}
  }
`;

import React, { ReactNode, useContext, createContext, useState } from 'react';

type KondutoContextType = {
  customerId: string;
  setCustomerId: (value: string) => void
};

const DEFAULT = 'PUBLIC';

const KondutoContext = createContext<KondutoContextType>({
  customerId: DEFAULT,
  setCustomerId: (value) => {}
});

export const useKondutoContext = () => useContext(KondutoContext);

type Props = {
  children: ReactNode
};

export function KondutoContextProvider({ children }: Props) {
  const [customerId, setCustomerId] = useState(DEFAULT);

  return (
    <KondutoContext.Provider value={{ customerId, setCustomerId }}>
      {children}
    </KondutoContext.Provider>
  );
};

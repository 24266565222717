// @ts-nocheck

import CardValidator from "card-validator";
import { Controller } from "react-hook-form";
import { INPUT_CREDIT_CARD_NUMBER_NAME } from "./constants";
import {
  Label,
  InputContainer,
  InputIconContainer,
  Input,
  InputIconErrorContainer,
  InputError,
} from "./styles";
import { usePaymentMethod } from "../../../contexts/payment-method";
import NumberFormat from "react-number-format";

function validateCardNumber(
  value: string,
  paymentMethod: string,
  bins?: [string],
  issuer?: string
) {
  const digits = value.replace(/[^\d]/g, "");

  if (paymentMethod === "ticketlog") {
    // Para cartões ticketlog, deve-se verificar apenas o bin.

    const TICKETLOG_BIN = "605680";
    const bin = digits.substring(0, 6);

    if (!bin.includes(TICKETLOG_BIN)) {
      return `Cartão não emitido por TicketLog.`;
    }

    return;
  }

  if (bins?.length > 0) {
    const bin = digits.substring(0, 6);
    if (!bins.includes(parseInt(bin, 10))) {
      return `Cartão não emitido por ${issuer}.`;
    }
  }

  const validation = CardValidator.number(digits, {
    maxLength: 16,
  });

  if (!validation.isValid || !validation.isPotentiallyValid) {
    return "Número de cartão inválido.";
  }

  const acceptedCards = [
    "american-express",
    "mastercard",
    "visa",
    "elo",
    "hiper",
    "hipercard",
  ];

  if (!acceptedCards.includes(validation.card?.type)) {
    return "Bandeira de cartão não aceita.";
  }
}

const cardNumberMask = (value: string) => {
  const digits = value.replace(/[^\d]/g, "");
  const maxLength = 16;
  const validation = CardValidator.number(digits, {
    maxLength,
  });

  if (validation.isPotentiallyValid) {
    const { type } = validation.card || {};
    if ("american-express" === type) {
      return "#### ###### #####";
    }
  }

  return "#### #### #### ####";
};

type CardNumberProps = {
  control: Function;
  errors: any;
};

export default function CardNumber({ control, errors }: CardNumberProps) {
  const { paymentMethod } = usePaymentMethod();
  const bins = [];
  const issuer = "";

  return (
    <>
      <Label error={errors} htmlFor={INPUT_CREDIT_CARD_NUMBER_NAME}>
        Número do cartão
      </Label>
      <InputContainer>
        <InputIconContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
            />
          </svg>
        </InputIconContainer>
        <Controller
          name={INPUT_CREDIT_CARD_NUMBER_NAME}
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: "Insira o número do cartão.",
            },
            validate: (value: string) =>
              validateCardNumber(value, paymentMethod, bins, issuer),
          }}
          render={({ field: { value, onChange, onBlur, ref } }) => (
            <NumberFormat
              format={cardNumberMask(value)}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              customInput={Input}
              autoComplete="cc-number"
              error={errors}
              getInputRef={ref}
            />
          )}
        />
        {errors && (
          <InputIconErrorContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </InputIconErrorContainer>
        )}
      </InputContainer>
      {errors && <InputError>{errors?.message}</InputError>}
    </>
  );
}

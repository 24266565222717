import { useKondutoContext } from "contexts/konduto";
import React, { Fragment, useEffect } from "react";

type KondutoProps = {
  page: string;
};

export default function Konduto({ page }: KondutoProps) {
  const { setCustomerId } = useKondutoContext();

  useEffect(() => {
    function generateCustomerID() {
      var visitorID;

      (function () {
        var period = 300;
        var limit = 20 * 1e3;
        var nTry = 0;
        var intervalID = setInterval(function () {
          var clear = limit / period <= ++nTry;
          if (
            typeof window.Konduto !== "undefined" &&
            typeof window.Konduto.getVisitorID !== "undefined"
          ) {
            visitorID = window.Konduto.getVisitorID();
            setCustomerId(visitorID);
            clear = true;
          }
          if (clear) {
            clearInterval(intervalID);
          }
        }, period);
        // @ts-ignore
      })(visitorID);
    }

    (function () {
      var period = 300;
      var limit = 20 * 1e3;
      var nTry = 0;
      var intervalID = setInterval(function () {
        var clear = limit / period <= ++nTry;
        if (
          typeof window.Konduto !== "undefined" &&
          typeof window.Konduto.sendEvent !== "undefined"
        ) {
          window.Konduto.sendEvent("page", page); // Disparo programático de evento

          if (page === "checkout") {
            generateCustomerID();
          }

          clear = true;
        }
        if (clear) {
          clearInterval(intervalID);
        }
      }, period);
      // @ts-ignore
    })(page);
  }, [page, setCustomerId]);

  return <Fragment />;
}

import React, { ReactNode, useContext, createContext, useState } from 'react';

type RiskifiedContextType = {
  sessionId: string;
  setSessionId: (value: string) => void
};

const DEFAULT = '';

const RiskifiedContext = createContext<RiskifiedContextType>({
  sessionId: DEFAULT,
  setSessionId: (value) => {}
});

export const useRiskifiedContext = () => useContext(RiskifiedContext);

type Props = {
  children: ReactNode
};

export function RiskifiedContextProvider({ children }: Props) {
  const [sessionId, setSessionId] = useState(DEFAULT);
  return (
    <RiskifiedContext.Provider value={{ sessionId, setSessionId }}>
      {children}
    </RiskifiedContext.Provider>
  );
};

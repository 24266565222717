import { Theme } from "./types";

const mtix: Theme = {
  coupon: true,
  whitelabel: true,
  displayFees: true,
  logo: "mtix.png",
  colors: {
    primary: "#0049C6",
    secondary: "#0049C6",
  },
};

export default mtix;

import React from 'react'
import styled from 'styled-components/macro'
import tailwind from 'twin.macro'
import { useLocation, useHistory } from 'react-router-dom'

const FullWidthHR = styled.hr`
   ${tailwind`
      mt-0
      mb-0
      position: absolute
   `}
   margin-left: -36px;
   
   width: calc(100% + 2px);
`;

const NavContainer = styled.nav`
   ${tailwind`
      flex
      flex-row
      items-center
      justify-center
      mb-2
   `}
   margin-left: -18px;
`;

const ButtonContainer = styled.div`
   ${tailwind`
      flex-none
   `}
`;


const TextContainer = styled.div`
   ${tailwind`
      flex
      flex-col 
      justify-center
      object-center
      w-full
      h-full
   `}
   h3 {
      ${tailwind`
         text-base
         font-semibold
         text-gray-900
      `}
   }
`;


export default function NavBar({ title }) {
   const history = useHistory();

   const handleGoBack = () => {
      history.goBack();
   };

   return (
      <nav style={{ backgroundColor: '#fff', padding: '10px', textAlign: 'center' }}>
         <NavContainer>
            <ButtonContainer>
            <button
               onClick={handleGoBack}
               style={{ marginTop: '5px', marginBottom: '5px', padding: '5px', cursor: 'pointer' }}
            >
              
              
               <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M15 18L9 12L15 6"
                     stroke="#2D161E"
                     strokeWidth="2"
                     strokeLinecap="round"
                     strokeLinejoin="round"
                  />
               </svg>
            </button>
            </ButtonContainer>
            <TextContainer>
               <h3 style={{ margin: '0px' }}>{title}</h3>
            </TextContainer>
         </NavContainer>
         <FullWidthHR />
      </nav>
   );
}

import { Fragment } from 'react'
import tailwind from 'twin.macro'
import styled from 'styled-components'
import { Theme } from 'themes/types'
import Modal from 'components/Modal/Modal'

const Icon = styled.div`
   ${tailwind`
    mx-auto
    flex
    items-center
    justify-center
    h-16
    w-16
    rounded-full
  `}

   svg {
      ${tailwind`
      animate-spin
      h-16
      w-16
    `}

      ${({ theme }: { theme: Theme }) =>
         `
    color: ${theme.colors.primary};
  `}

    circle {
         ${tailwind`
        opacity-50
      `}
      }

      path {
         ${tailwind`
        opacity-20
      `}
      }
   }
`

const Content = styled.div`
   ${tailwind`
    mt-4
  `}

   p {
      ${tailwind`
      text-base
      text-gray-500
      mb-3
    `}
   }
`

const Heading = styled.h3`
   ${tailwind`
    text-xl
    leading-6
    font-medium
    text-gray-900
    text-center
    mb-10
  `}
`

const Container = styled.div`
   ${tailwind`
    mt-3
    text-center
    sm:mt-5
  `}
`

type PaymentProcessingModalProps = {
   visible: boolean
   control?: any
}

export default function PaymentProcessingModal({ visible, control }: PaymentProcessingModalProps) {
   if (!visible) {
      return <Fragment />
   }

   return (
      <Modal>
         <Heading>Aguarde...</Heading>
         <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
               <radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)">
                  <stop offset="0" stop-color="#FF0060" data-darkreader-inline-stopcolor=""></stop>
                  <stop offset=".3" stop-color="#FF0060" stop-opacity=".9" data-darkreader-inline-stopcolor=""></stop>
                  <stop offset=".6" stop-color="#FF0060" stop-opacity=".6" data-darkreader-inline-stopcolor=""></stop>
                  <stop offset=".8" stop-color="#FF0060" stop-opacity=".3" data-darkreader-inline-stopcolor=""></stop>
                  <stop offset="1" stop-color="#FF0060" stop-opacity="0" data-darkreader-inline-stopcolor=""></stop>
               </radialGradient>
               <circle
                  transform-origin="center"
                  fill="none"
                  stroke="url(#a12)"
                  stroke-width="25"
                  stroke-linecap="round"
                  stroke-dasharray="200 1000"
                  stroke-dashoffset="0"
                  cx="100"
                  cy="100"
                  r="70"
               >
                  <animateTransform
                     type="rotate"
                     attributeName="transform"
                     calcMode="spline"
                     dur="1.5"
                     values="360;0"
                     keyTimes="0;1"
                     keySplines="0 0 1 1"
                     repeatCount="indefinite"
                  ></animateTransform>
               </circle>
               <circle
                  transform-origin="center"
                  fill="none"
                  opacity=".2"
                  stroke="#FF0060"
                  stroke-width="25"
                  stroke-linecap="round"
                  cx="100"
                  cy="100"
                  r="70"
                  data-darkreader-inline-stroke=""
               ></circle>
            </svg>
         </Icon>
         <Container>
            <Content>
               <p>Estamos processamos seu pagamento.</p>
            </Content>
         </Container>
      </Modal>
   )
}

import { useQuery } from "hooks/query";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import {
  Label,
  GridItem,
  Input,
  InputError,
  InputContainer,
  LeadingContainer,
  InputIconErrorContainer,
} from "./styles";

const INPUT_CUSTOMER_PHONE_NAME = "customer.phone";

type PhoneProps = {
  control: any;
  errors: any;
  phone?: string;
};

export default function Phone({ control, errors, phone }: PhoneProps) {
  const query = useQuery();
  const defaultValue = query.get("phone") || phone;  

  return (
    <GridItem>
      <Label error={errors} htmlFor={INPUT_CUSTOMER_PHONE_NAME}>
        Telefone
      </Label>
      <InputContainer>
        <LeadingContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
            />
          </svg>
        </LeadingContainer>
        <Controller
          control={control}
          name={INPUT_CUSTOMER_PHONE_NAME}
          defaultValue={defaultValue ?? ""}
          rules={{
            required: {
              value: true,
              message: "Insira o telefone.",
            },
            validate: {
              value: (value: string) => {
                const digits = value?.replace(/[^\d]/g, "");
                if (digits.length < 11) {
                  return "Telefone incompleto.";
                }
              },
            },
          }}
          render={({ field: { value, onChange, onBlur, ref } }) => (
            <NumberFormat
              format="(##) #####-####"
              type="tel"
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              getInputRef={ref}
              customInput={Input}
              // error={errors}
              placeholder="(00) 00000-0000"
              autoComplete="tel-national"
            />
          )}
        />
        {errors && (
          <InputIconErrorContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </InputIconErrorContainer>
        )}
      </InputContainer>
      {errors && <InputError>{errors?.message}</InputError>}
    </GridItem>
  );
}

import zapi from 'api'

export interface Order {
   payment_link: string
   debts: Debt[]
   protocol: string
}

interface Debt {
   id: string
   amount: number
   title: string
   description: string
   due_date: string
   type: string
}

interface Payload {
   amount: number
   store: string
   description: string
   license_plate?: string
}

// Função para criar um débito na ZAPI
export const createOrder = async (amount: number, sID: string, license_plate: string): Promise<Order> => {
   const payload: Payload = {
      amount,
      store: sID,
      description: 'Debito App Divid+'
   }

   if (license_plate) {
      payload.license_plate = license_plate
   }

   try {
      const response = await zapi.post(`/business/order`, payload)

      const order: Order = response.data
      return order
   } catch (error) {
      throw new Error(error.message)
   }
}

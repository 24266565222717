import { useOrder } from "contexts/order";
import {
  Centerize,
  Container,
  Content,
  Heading,
  Icon,
  ModalContainer,
  ModalContent,
  ModalDialog,
  Overlay,
} from "./styles";
import { useInstallments } from "contexts/installments";
import { useEffect, useState } from "react";



export default function Loading() {
  const { fetched } = useOrder();
  const { states } = useInstallments();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (fetched && !states.loading) {
      setLoading(false);
    }
  }, [fetched, states.loading])

  if (!loading) {
    return null;
  }

  return (
    <Container className="bg-white" role="dialog" aria-modal="true">
      <ModalContainer>
        <Overlay />
        <Centerize>&#8203;</Centerize>
        <ModalDialog>
          
        <Icon>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
          </Icon>
          <ModalContent>
            <Heading>Carregando ...</Heading>
            <Content>
              <p>Aguarde enquanto processamos as informações do seu pedido. </p>
            </Content>
          </ModalContent>
        </ModalDialog>
      </ModalContainer>
    </Container>
  );
}

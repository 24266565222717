import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

let baseURL = "https://api.usezapay.com.br";

switch (process.env.REACT_APP_ENV) {
  case "staging":
    baseURL = "https://67f4-2804-ad8-4500-2000-3810-2bf3-3b5e-c9dd.ngrok-free.app";
    break;
  case "production":
    baseURL = "https://api.usezapay.com.br";
    break;
  case "sandbox":
    baseURL = "https://api.sandbox.usezapay.com.br";
    break;
  default:
    baseURL = "http://localhost:80/";
    break;
}


interface AuthResponse {
  token: string;
}


const authenticate = async (username: string, password: string): Promise<string> => {
  try {
    const response: AxiosResponse<AuthResponse> = await axios.post(`${baseURL}/authentication/`, {
      username,
      password,
    });

    const authToken = response.data.token;
    
    return authToken;
  } catch (error) {

    throw new Error('Falha na autenticação'); 
  }
};


const axiosConfig: AxiosRequestConfig = {
  baseURL,
  headers: {
    'Content-Type': 'application/json',
    // 'Accept': 'application/json; version=v2.0;'
  },
};


const zapi = axios.create(axiosConfig);


zapi.interceptors.request.use(
  async (config) => {
    const authToken = await authenticate('igor.oliveira', 'Abcd@1234');
    // const authToken = await authenticate("zapay","userpassword");

    config.headers.Authorization = `JWT ${authToken}`;
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default zapi;


import zapay from "./zapay";
import portoSeguro from "./porto-seguro";
import bancopan from "./bancopan";
import mtix from "./mtix";
import bb from "./bb";

export default function themeProvider(theme: string) {
  switch (theme) {
    case "porto-seguro":
      return portoSeguro;
    case "bancopan":
        return bancopan;
    case "mtix":
        return mtix;
    case "bb":
        return bb;
    default:
      return zapay;
  }
}

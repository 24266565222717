import styled from "styled-components/macro";
import tailwind from "twin.macro";

export const Container = styled.div`
  ${tailwind`
    fixed
    inset-0
    overflow-y-auto
  `}
  z-index: 999;
  ${({ hidden }) => (hidden ? tailwind`hidden` : undefined)}
`;

export const ModalContainer = styled.div`
  ${tailwind`
    flex
    items-center
    justify-center
    min-h-screen
    pt-4
    px-4
    pb-20
    text-center
    sm:block sm:p-0
  `}
`;

export const Overlay = styled.div`
  ${tailwind`
    fixed
    inset-0
    bg-gray-500
    bg-opacity-75
    transition-opacity
  `}
`;

export const ModalDialog = styled.div`
  ${tailwind`
    inline-block
    align-bottom
    bg-white
    rounded-lg
    px-5
    py-6
    text-left
    overflow-hidden
    shadow-xl
    transform
    transition-all
    sm:my-2
    sm:align-middle
    sm:max-w-sm sm:w-full sm:p-6
  `}
`;

export const Centerize = styled.span`
  ${tailwind`
    hidden
    sm:inline-block
    sm:align-middle
    sm:h-screen
  `}
`;

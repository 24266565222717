import React,  { createContext, useState } from 'react';

type CreditCardMethodContextType = {
  creditCardMethod: string;
  setCreditCardMethod: (value: string) => void
};

const DEFAULT = '1-card';

const CreditCardMethodContext = createContext<CreditCardMethodContextType>({
  creditCardMethod: DEFAULT,
  setCreditCardMethod: (value) => {}
});

export const useCreditCardMethod = () => React.useContext(CreditCardMethodContext);

type Props = {
  children: React.ReactNode
};

export function CreditCardMethodProvider({ children }: Props) {
  const [creditCardMethod, setCreditCardMethod] = useState(DEFAULT);
  return (
    <CreditCardMethodContext.Provider value={{ creditCardMethod, setCreditCardMethod }}>
      {children}
    </CreditCardMethodContext.Provider>
  );
};

export const calculateTotalAmount = (
  payment_plans,
  selectedInstallments,
  paymentMethod
) =>
  payment_plans.find(
    ({ type, installments }) =>
      (paymentMethod === "credit" &&
        type === paymentMethod &&
        installments === selectedInstallments) ||
      (type !== "credit" && paymentMethod === type)
  );

// import React, { useEffect, useState } from "react";
import tailwind from "twin.macro";
import _orderBy from 'lodash/orderBy';
import styled from "styled-components/macro";
// import InstallmentOption from "./OptionFees";
// import Select from "./Select";
// import { useOrder } from "../../../contexts/order";
// import { useInstallments } from "contexts/installments";

type ListProps = {
  open: boolean;
};

const List = styled.ul`
  display: flex;
  
  ${({ open }: ListProps) => (open ? tailwind`` : tailwind`hidden`)}

  ${tailwind`
    border
    absolute
    z-10
    mt-1
    w-full
    bg-white
    shadow-lg
    max-h-60
    rounded-md
    py-1
    text-base
    ring-1
    ring-black
    ring-opacity-5
    overflow-auto
    sm:text-sm
  `}

  gap: 0.5rem;
  flex-direction: column;
`;

const Label = styled.label`
  ${tailwind`
    block
    text-sm
    font-medium
    text-gray-700
  `}
`;

const Container = styled.div`
  ${tailwind`
    mt-1
    relative
  `}
`;

export default function Installments() {
  return (<></>)
  // const { dataPlans } = useInstallments();
  // const [display, setDisplay] = useState(false);

  // const installments = _orderBy(
  //   dataPlans.plans.filter(({ type }) => type === "credit"), 
  //   ['installments'], 
  //   ['desc']
  // );

  // const hideOptions = () => setDisplay(false);
  // const toggleDisplayOptions = () => setDisplay(!display);

  // const baseAmount = 1000;

  // useEffect(() => {
  //   if (display) {
  //     document.addEventListener("click", hideOptions);
  //   }

  //   return () => {
  //     document.removeEventListener("click", hideOptions);
  //   };
  // }, [display]);

  // return (
  //   <>
  //     <Label>Parcelas</Label>
  //     <Container>
  //       <Select baseAmount={baseAmount} handleClick={toggleDisplayOptions} />
  //       <List open={display} tabIndex="-1" role="listbox">
  //         {installments.map(({ installments, amount, totalAmount, fee }) => (
  //           <InstallmentOption
  //             key={`installment.option.${installments}`}
  //             fee={fee}
  //             installments={installments}
  //             amount={amount}
  //             totalAmount={totalAmount}
  //             baseAmount={baseAmount}
  //           />
  //         ))}
  //       </List>
  //     </Container>
  //   </>
  // );
}

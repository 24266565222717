import React, { useEffect, useState } from 'react'
import tailwind from 'twin.macro'
import _orderBy from 'lodash/orderBy'
import styled from 'styled-components/macro'
import InstallmentOption from './../../components/Payment/Installments/OptionFees'
import Select from './../../components/Payment/Installments/Select'
import Header from './../../components/Header'
import { useOrder } from '../../contexts/order'
import { useInstallments } from 'contexts/installments'
import Loading from '../../components/Loading/Loading'
import { useQuery } from 'hooks/query'
import { useHistory, useParams } from 'react-router-dom'
import NavBarInstallments from 'components/Navigation/NavBarInstallments'
import ScrollToTopOnMount from 'utils/scrollTop'

const List = styled.ul`
   display: flex;
   gap: 0.5rem;
   flex-direction: column;

   ${tailwind`
    z-10
    mt-1
    bg-white  
    rounded-md
    pt-1
    pb-4
    text-base
  `}
`

const Label = styled.label`
   ${tailwind`
    block
    text-xl
    font-semibold
    leading-none
    pt-2

  `}
`
const Value = styled.label`
   ${tailwind`
    text-3xl
    font-medium
    mb-6
    pt-6
    flex
    flex-row
    items-end
    leading-none
  
  `}
  span {
      ${tailwind`
      text-sm
      font-semibold
      object-left-bottom
      pr-1
      
      `}
      
  }
  p {
      ${tailwind`
      leading-none
      pb-0
      
      `}
      
  }
`
const Title = styled.label`
   ${tailwind`
    block
    text-base
    font-medium
    pt-2
  
  `}
`

const WrappedContainer = styled.div`
   ${tailwind`
      h-screen
      mx-5
      max-w-md
      md:mx-auto
  `}
  
`

const FullWidthHR = styled.hr`
   ${tailwind`
      mt-0
      mb-0
      position: absolute
      md:max-w-lg
   `}
   margin-left: -32px;
   width: calc(100% + 8px); 
`;

const HeaderContainer = styled.div`
  ${tailwind`
    flex
    flex-col
    items-start
    
    `}
    p {
      ${tailwind`

      font-medium
  
    `}
    }
    span {  

      ${tailwind`
      text-xl
      font-medium
      leading-none
      `}
    }
    `;


const WrappedContent = styled.div`

      ${tailwind`
         mt-4
         
      `}

      p {
         ${tailwind`
            text-base
            font-medium
         `}
      }
      `;

const Link = styled.button`

   ${tailwind`
    w-full
    bg-pink-500
    text-white
    hover:bg-pink-600
    items-center
    focus:ring-pink-500
    rounded-3xl	
    text-base
    font-semibold
    focus:outline-none
    mt-4
    mb-6
    h-12
    text-center
  `}
  background-color: rgba(255, 0, 96, 1);
   strong {
      ${tailwind`
      mx-1
    `}
   }
`

export default function InstallmentsPage() {
   const history = useHistory()
   const query = useQuery()
   const { id } = useParams<{ id: string }>()
   const { dataPlans, states } = useInstallments()
   const [display, setDisplay] = useState(false)

   const installments = _orderBy(
      dataPlans?.plans.filter(({ type }) => type === 'credit'),
      ['installments'],
      ['asc']
   )

   const hideOptions = () => setDisplay(false)
   const toggleDisplayOptions = () => setDisplay(!display)
   const value = query.get('total')
   const [formattedValue, setFormattedValue] = useState('');

   useEffect(() => {
      const formattedValue = Number(value).toLocaleString('pt-BR', {
         style: "decimal", 
          minimumFractionDigits: 2,
          maximumFractionDigits: 2

      });

      
      setFormattedValue(formattedValue);
   }, [value]);

   useEffect(() => {
      if (display) {
         document.addEventListener('click', hideOptions)
      }

      return () => {
         document.removeEventListener('click', hideOptions)
      }
   }, [display])

   if (states.loading) {
      return <Loading />
   }

   return (
      <>
         <ScrollToTopOnMount />
         <WrappedContainer>
            <Header />
            <NavBarInstallments title="Simulação de Pagamento"/>
            <HeaderContainer> 
            <Label>
               
               Valor
               </Label>
            <Value>
               <span>R$ </span> <p>{formattedValue}</p>
               
               </Value>
            </HeaderContainer>
            <FullWidthHR/>
            
            <WrappedContent>
            <Title>Escolha o parcelamento</Title>
            

            <Select handleClick={toggleDisplayOptions} />
            
            <List open={display} tabIndex="-1" role="listbox">
               {installments.map(({ installments, amount, totalAmount, fee }) => (
                  <InstallmentOption
                     key={`installment.option.${installments}`}
                     fee={fee}
                     installments={installments}
                     amount={amount}
                     totalAmount={totalAmount}
                  />
                  
                  
               ))}
              
             

               <Link
                  onClick={() => {
                     history.push(`/${id}/checkout`)
                  }}
               >
                  Ir para o pagamento
               </Link>
            </List>
            </WrappedContent>      
         </WrappedContainer>
      </>
   )
}

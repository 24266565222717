import { Wrapper } from "./styles";
import {ZapayLogo} from "components/zapay-logo";
import { PartnerLogo } from "./partner-logo";
import { useTheme } from "styled-components";

export const Footer = () => {
    const { footer } = useTheme();

    if (!footer) {
        return null;
    }

    return (
        <Wrapper>
            <div className="w-container">
                <PartnerLogo />
                <ZapayLogo />
            </div>
        </Wrapper>
    );
}
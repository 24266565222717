import React, { ReactNode, createContext, useState, useContext } from 'react';

type CouponContextType = {
  validated: boolean;
  code?: string;
  discount?: number;
  is_percent?: boolean;
  setCoupon: (value: any) => void
};

const DEFAULT = {
  validated: false
};

const CouponContext = createContext<CouponContextType>({
  ...DEFAULT,
  setCoupon: (value) => {}
});

export const useCoupon = () => useContext(CouponContext);

type Props = {
  children: ReactNode
};

export function CouponContextProvider({ children }: Props) {
  const [coupon, setCoupon] = useState(DEFAULT);
  return (
    <CouponContext.Provider value={{ ...coupon, setCoupon }}>
      {children}
    </CouponContext.Provider>
  );
};

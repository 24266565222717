import { Props } from './types';
import { ChipIcon } from './chip-icon';
import { useModel } from './model';
import { CardFace, Wrapper } from './styles';

export const CardInfos = (props: Props) => {
  const model = useModel(props);

  return (
    <Wrapper type={model.issuer}>
      <div className={`perspective ${model.flip ? 'flip' : ''}`}>
        <CardFace className="face front">
          <div className="row icon-flag">
            <ChipIcon />
            {model.flag ? (
              <img className="flag" src={model.flag.src} alt={model.flag.alt} />
            ) : null}
          </div>
          <div className="row number">
            <p className="text-txl font-bold">{model.number}</p>
          </div>
          <div className="row card-bottom">
            <p className="text-tmd font-bold">{model.holderName}</p>
            <div>
              <span className="text-txs">Válido até</span>
              <p className="text-tmd font-bold">{model.validity}</p>
            </div>
          </div>
        </CardFace>
        <CardFace className="face back">
          <div className="row cvv">
            <p className="text-txl font-bold">CVV</p>
            <p className="text-txl">{model.cvv}</p>
          </div>
        </CardFace>
      </div>
    </Wrapper>
  );
};

import React, { Fragment } from 'react';
import CrediCardMethods from './Methods';
import CrediCardForm from './Form';
import { CreditCardMethodProvider } from 'contexts/credit-card-method';
import { usePaymentMethod } from 'contexts/payment-method';
import { CreditCardFlipContextProvider } from 'contexts/credit-card-flip';

type CreditCardProps = {
  control: Function;
  register: Function;
  errors: any;
};

export default function CreditCard({ control, register, errors }: CreditCardProps) {
  const { paymentMethod } = usePaymentMethod();
  const supportedPaymentMethods = ['credit', 'ticketlog'];

  if (!supportedPaymentMethods.includes(paymentMethod)) {
    return <Fragment />;
  }

  return (
    <CreditCardMethodProvider>
      <CrediCardMethods />
      <CreditCardFlipContextProvider>
        <CrediCardForm
          control={control}
          register={register}
          errors={errors?.card}
        />
      </CreditCardFlipContextProvider>
    </CreditCardMethodProvider>     
  );
}

import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
import tailwind from 'twin.macro'
import { useTheme } from 'styled-components'
import { usePaymentMethod } from 'contexts/payment-method'
import { useOrder } from 'contexts/order'
import { useParams } from 'react-router-dom'
import { getStore } from 'utils/stores'
import TotalAmount from 'components/Amount/Total'
import CardValidator from "card-validator";
import { CARD_FLAGS } from 'components/Payment/CreditCard/card-infos/constants'

// type SummaryProps = {
//   card: {},
//   store: any,
//   customer: {}
// }

const Icon = styled.div`
   ${tailwind`
    mx-auto
    flex
    items-center
    justify-center
  `}

   svg {
      ${tailwind`
      animate-pulse
      h-28 
      w-28
      text-green-600
    `}
   }
`
const Heading = styled.h3`
   ${tailwind`
    text-lg
    leading-6
    font-medium
    text-gray-900
    mb-3
    pt-2
    leading-9
  `}
`

const Link = styled.a`
   ${tailwind`
    inline-flex
    justify-center
    rounded-md
    border
    px-4
    py-2
    sm:text-sm
  `}
`

const PinkButton = styled(Link)`
   ${tailwind`
    bg-pink-500
    text-white
    hover:bg-pink-600
    items-center
    focus:ring-pink-500
    rounded-3xl	
    font-medium
    focus:outline-none
    mb-6
    h-12
    text-center
    mx-4
    self-center
    w-full
  `}
   background-color: rgba(255, 0, 96, 1);
`

const HeadingContainer = styled.div`
   ${tailwind`
    flex
    flex-col
    items-start
    pl-6

    `}
   p {
      ${tailwind`
      pb-2
      font-medium
  
    `}
   }
`

const MainHeading = styled.h3`
   ${tailwind`
    text-left
    text-3xl	
    leading-6
    font-semibold
    text-gray-900
    pb-8

    
  `}
`

const HeadingList = styled.h3`
   ${tailwind`
    text-lg
    leading-6
    text-gray-900
    flex
    items-start
    mb-3
    
  `}
`
const Content = styled.div`
   ${tailwind`
    mt-3
    mb-5
  `}

   p {
      ${tailwind`
      text-sm
      text-gray-500
    `}
   }
`

const ItemList = styled.div`
   ${tailwind`
    flex
    flex-row
    items-center 
    mb-2
    space-x-2

  `}
   align-items: flex-start;
`

const CardContainer = styled.div`
  width: 100%;
   ${tailwind`
    flex
    flex-row
    items-center 
    mb-2
    space-x-2
  `}

   align-items: flex-start;

   .flagdiv {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 50%;
    background-color: rgba(247, 247, 247, 1);
    display: flex;
    padding: 5px;
   }

   .flag {
      max-width: 100%;
      height: auto;
    }
`

const ContentList = styled.div`
   ${tailwind`
    mt-3
 
   
  `}

   p {
      ${tailwind`
      text-sm
      text-gray-500
    `}
   }
   ${ItemList} {
      align-items: flex-start; /* Reset alignment for ItemList within ContentList */
   }
`

const Container = styled.div`
   ${tailwind`
    mt-7
    text-center
    sm:mt-5
  `}
`

const ButtonContainer = styled.div`
   ${tailwind`
    flex
    pb-6
    flex-col
    px-6
    my-8
  `}
`

const OrderDetails = styled.div`
   ${tailwind`
    mt-3
    mx-6
    mb-3
    py-4
  `}

   dl {
      ${tailwind`
      grid 
      grid-cols-2 
      gap-x-4
     
    `}
   }

   dt,
   dd {
      ${tailwind`
      py-1
    `}
   }

   dt {
      ${tailwind`
      text-sm font-medium text-gray-500
    `}
   }

   dd {
      ${tailwind`
      text-sm 
      font-bold
      text-gray-900
    `}
   }
`

const DetailsItem = styled.div`
   ${tailwind`
    flex
    items-center
    justify-between
    py-2

  `}
`

const DetailsItemF = styled.div`
   ${tailwind`
    flex
    items-center
    justify-between
    pt-2

  `}
`

const HeadingOrder = styled.h3`
   ${tailwind`
    flex
    items-start
    text-lg
    leading-6
    font-medium
    text-gray-900
    mb-3
  `}
`

const List = styled.ul`
   ${tailwind`
    px-6
    flex 
    flex-col
    items-start 
    text-left
    
  `}
`

const TitleList = styled.h3`
   ${tailwind`
    font-medium

  `}
`

export default function Summary({data}) {
  const { id } = useParams<{ id: string }>()
  const store = getStore(id)
  
  const parcelsAmount = TotalAmount("parcels")
  const totalAmount = TotalAmount("total")
  const totalTax = TotalAmount("tax")
  const totalService = TotalAmount("")

  const cardType = CardValidator.number(
    data.card.number.replace(/[^\d]/g, "")
  );
  const brand = cardType.card?.type;
  const flag = CARD_FLAGS[brand]
  
   return (
      <Container>
         <Container>
            <HeadingContainer>
               <p> Oficina mecânica </p>
               <MainHeading>{store?.name}</MainHeading>
            </HeadingContainer>
            <hr />
         </Container>

         <Content>
            <List>
               <Heading>Dados do pagador</Heading>

               <TitleList>{data?.card.holder}</TitleList>
               <p>CPF: {data?.card.document}</p>
            </List>
         </Content>

         <hr></hr>

         <Content>
            <List>
               <Heading>Forma de pagamento</Heading>
               <CardContainer>
                <div className="flagdiv">
                  {flag ? (<img className="flag" src={flag.src} alt={flag.alt} />) : null}
                </div>

                
                  {/* <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
                        fill="#F7F7F7"
                     />
                     <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.179 28.7995C22.9949 29.7975 21.459 30.4 19.7807 30.4C16.0358 30.4 13 27.4003 13 23.7C13 19.9997 16.0358 17 19.7807 17C21.459 17 22.9949 17.6025 24.179 18.6005C25.363 17.6025 26.8989 17 28.5773 17C32.3221 17 35.358 19.9997 35.358 23.7C35.358 27.4003 32.3221 30.4 28.5773 30.4C26.8989 30.4 25.363 29.7975 24.179 28.7995Z"
                        fill="#ED0006"
                     />
                     <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.179 28.7995C25.6369 27.5706 26.5614 25.742 26.5614 23.7C26.5614 21.658 25.6369 19.8294 24.179 18.6005C25.363 17.6025 26.8989 17 28.5772 17C32.3221 17 35.3579 19.9997 35.3579 23.7C35.3579 27.4003 32.3221 30.4 28.5772 30.4C26.8989 30.4 25.363 29.7975 24.179 28.7995Z"
                        fill="#F9A000"
                     />
                     <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.179 28.7995C25.6369 27.5706 26.5614 25.742 26.5614 23.7C26.5614 21.6581 25.6369 19.8295 24.179 18.6006C22.7211 19.8295 21.7966 21.6581 21.7966 23.7C21.7966 25.742 22.7211 27.5706 24.179 28.7995Z"
                        fill="#FF5E00"
                     />
                  </svg> */}

                  <div>
                     <TitleList>{brand.toUpperCase()} •••• {data?.card.number.slice(-4)}</TitleList>
                     <p>{parcelsAmount}</p>
                  </div>
                  <br />
               </CardContainer>
            </List>
         </Content>

         <hr></hr>

         <OrderDetails>
            <HeadingOrder>Detalhes do pedido</HeadingOrder>

            <DetailsItem>
               <dt>Serviço mecânico</dt>
               <dt>{totalService}</dt>
            </DetailsItem>
            <hr></hr>
            <DetailsItem>
               <dt>Taxa de cartão</dt>
               <dt>{totalTax}</dt>
            </DetailsItem>

            <hr></hr>
            <DetailsItemF>
               <dd>Total</dd>
               <dd>{totalAmount}</dd>
            </DetailsItemF>
         </OrderDetails>

         <hr></hr>
      </Container>
   )
}

import { InstallmentsContextProvider, useInstallments } from 'contexts/installments'
import { PaymentMethodContextProvider } from 'contexts/payment-method'
import './styles.css'
import '@fontsource-variable/inter'
import { OrderContextProvider, useOrder } from 'contexts/order'
import { KondutoContextProvider } from 'contexts/konduto'
import KondutoFingerPrint from 'components/FingerPrints/Konduto'
import BraspagFingerPrint from 'components/FingerPrints/Braspag'
import RiskifiedFingerPrint from 'components/FingerPrints/Riskified'
import { ThemeProvider } from 'contexts/themes'
import { Theme } from 'themes/types'
import Loading from 'components/Loading/Loading'
import Checkout from 'components/Checkout/Checkout'
import NotFound from 'components/NotFound/NotFound'
import { Footer } from 'components/footer'
import FirstScreen from 'pages/FirstScreen/FirstScreen'
import InstallmentsPage from 'pages/Installments/Installments'
import tailwind from 'twin.macro'
import styled from 'styled-components/macro'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './styles.css'
import Hotjar from '@hotjar/browser'

const Container = styled.div`
   ${tailwind`
    bg-white
    px-5
  `}
`

const Main = styled.main`
   ${tailwind`
    relative
    lg:grid
    lg:grid-cols-10
    xl:ml-20
    xl:mr-12
    lg:px-8
  `}

   @media (min-width: 1024px) {
      min-height: calc(100vh - 69px);
   }
`

const LeftBackground = styled.div`
   ${tailwind`
    hidden
    lg:block
    fixed
    top-0
    left-0
    w-2/3
    h-full
    bg-white
  `}
`

const RightBackground = styled.div`
   ${tailwind`
    hidden
    lg:block
    fixed
    top-0
    right-0
    w-2/6
    h-full
    bg-gradient-to-b
    from-pink-800
    to-zapay
  `}
   ${({ theme }: { theme: Theme }) =>
      `
    --tw-gradient-from: ${theme.colors.primary};
    --tw-gradient-to: ${theme.colors.secondary};
  `}
`

function CheckoutApp() {
   const { states } = useInstallments()

   if (states.loading) {
      return <Loading />
   }

   return (
      <Container>
         <LeftBackground aria-hidden="true" />
         <RightBackground aria-hidden="true" />
         <Main>
            <PaymentMethodContextProvider>
               <Checkout />
            </PaymentMethodContextProvider>
         </Main>
         <Footer />
      </Container>
   )
}

export default function App() {
   const siteId = 3802011
   const hotjarVersion = 6

   Hotjar.init(siteId, hotjarVersion)

   return (
      <Router>
         <OrderContextProvider>
            <InstallmentsContextProvider>
               <KondutoContextProvider>
                  <RiskifiedFingerPrint />
                  <KondutoFingerPrint page="checkout" />
                  <BraspagFingerPrint />
                  <ThemeProvider>
                     <Switch>
                        {/* <Route path="/asaas">
                           <NotFound />
                        </Route> */}

                        <Route path="/carregando">
                           <Loading />
                        </Route>

                        <Route path="/:id/checkout">
                           <CheckoutApp />
                        </Route>

                        <Route path="/:id/simulacao">
                           <InstallmentsPage />
                        </Route>

                        <Route path="/:id">
                           <FirstScreen />
                        </Route>
                     </Switch>
                  </ThemeProvider>
               </KondutoContextProvider>
            </InstallmentsContextProvider>
         </OrderContextProvider>
      </Router>
   )
}

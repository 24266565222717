import React, { Fragment, useEffect } from 'react';
import { useOrder } from 'contexts/order';
import { useRiskifiedContext } from 'contexts/riskified';

export default function RiskifiedFingerPrint(){
  const { protocol } = useOrder();
  const { setSessionId } = useRiskifiedContext();

  useEffect(() => {
  
    function onLoad() {
      window.RISKX.go(window.location.pathname)
    }

    if (!window.RISKX) {
      const store_domain = 'usezapay.com.br';
      const session_id = Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);
      setSessionId(session_id);
      const protocol = document.location.protocol === 'https:' ? 'https://' : 'http://';
      const url = `${protocol}beacon.riskified.com?shop=${store_domain}&sid=${session_id}`;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.async = true;
      script.onload = () => onLoad();

      document.body.appendChild(script);
    }
  }, [protocol, setSessionId]); 

  return <Fragment />;
}

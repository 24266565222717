import React, { useState } from 'react'
import styled from 'styled-components/macro'
import tailwind from 'twin.macro'
import { useHistory, useParams } from 'react-router-dom'
import { getStore } from 'utils/stores'
import { Order, createOrder } from 'components/Order/create-order'
import { useOrder } from 'contexts/order'
import Header from 'components/Header'

const Container = styled.div`
   ${tailwind`
    mr-10
    text-center
    mx-auto
    max-w-md 
  `}
`

const WrappedContainer = styled.div`
   ${tailwind`
    px-5
    mr-10
    text-center
    mx-auto
    max-w-md 
  `}
`

// const Icon = styled.div`
//    ${tailwind`
//     mx-auto
//     flex
//     justify-center
//     h-16
//     w-32
//   `}

//    svg {
//       ${tailwind`
//       animate-pulse
//       h-6
//       w-6
//       text-green-600
//     `}
//    }
// `

// const Heading = styled.h3`
//    ${tailwind`
//     text-lg
//     leading-6
//     font-medium
//     text-gray-900
//   `}
// `

const MainHeading = styled.h3`
   ${tailwind`
      text-3xl	
      font-semibold
      text-gray-900
      pb-3
      text-left
  `}

   ${(props) =>
      props.dashed &&
      `
      text-decoration-line: line-through;
      `}
`

const HeadingContainer = styled.div`
   ${tailwind`
    flex
    flex-col
    items-start
    `}

   p {
      ${tailwind`
      pb-2
      font-medium
      
    `}
   }
`

const Label = styled.div`
   ${tailwind`
    flex
    flex-col
    items-start
    font-medium
    text-lg
    mb-2
    mt-4
    `}

   p {
      ${tailwind`
      pb-2
      font-medium
    `}
   }
`

const InputContainer = styled.div`
   ${tailwind`
    relative
    h-8
    w-full
    mb-3 
  `}
`

const Input = styled.input`
   ${tailwind`
    h-12
    w-full
    pl-8
    rounded-lg
  `}
   border: 1px solid #d2d6dc;

   &::-webkit-inner-spin-button,
   &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
   &:focus {
      outline: none;
      border: 1px solid #d2d6dc;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
   }
`
const Input2 = styled.input`
   ${tailwind`
    h-12
    w-full
    pl-2
    rounded-lg
  `}
   border: 1px solid #d2d6dc;

   &::-webkit-inner-spin-button,
   &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
   }
   &:focus {
      outline: none;
      border: 1px solid #d2d6dc;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
   }
`

const Content = styled.div`
   ${tailwind`
    mt-2
  `}

   p {
      ${tailwind`
      text-sm
      text-gray-800
    `}
   }
`
const Prefix = styled.span`
   ${tailwind`
      absolute
      left-2
      top-3
      text-gray-500
      pointer-events-none
   `}
`

const ButtonContainer = styled.div`
   ${tailwind`   
    items-center
    max-w-md 
  `}
`

const PinkButton = styled.button`
   ${tailwind`
    w-full
    mt-12
    inset-x-2
    bg-pink-500
    text-white
    hover:bg-pink-600
    items-center
    focus:ring-pink-500
    rounded-3xl	
    font-medium
    focus:outline-none
    mb-6
    h-12
    text-center
  `}

   background-color: rgba(255, 0, 96, 1);
   ${(props) =>
      props.disabled &&
      `
   background-color:#F0F0F0;
   color: #A3A3A3; 
   pointer-events: none; 
 `}
`
const Tag = styled.div`
   ${tailwind`
   text-sm
   text-gray-400
   text-left
   ml-1
 `}
`

export default function FirstScreen() {
   const history = useHistory()
   const { id } = useParams<{ id: string }>()
   const store = getStore(id)
   const { setOrder } = useOrder()
   const [amount, setAmount] = useState<string>('')
   const [plate, setPlate] = useState<string>('')

   const handleSimulate = async (e) => {
      e.preventDefault()

      if (store.off) {
         alert(`Agradecemos seu interesse em parcelar a manutenção do seu veículo com a Zapay.
         
Sentimos informar que essa funcionalidade está desativada por tempo indeterminado.`)
         return
      }

      try {
         const amountInCents = parseFloat(amount.replace(/[^\d]/g, '')) || 0
         const amountInReal = amountInCents / 100

         const newOrder = await createOrder(amountInReal, id, plate)
         setOrder(newOrder)
         console.log(newOrder)

         history.push(`${id}/simulacao?total=${amountInReal}`)
      } catch (error) {
         console.error('Erro ao criar o débito:', error.message)
         alert('Erro ao processar a simulação, por favor tente novamente')
      }
   }

   const formatValue = (inputedValue) => {
      const clearValue = inputedValue.replace(/[^\d]/g, '')
      const cents = clearValue.slice(-2)
      const units = clearValue.slice(0, -2)

      setAmount(`${units},${cents}`)
   }

   return (
      <WrappedContainer>
         <Header />
         <Container>
            <HeadingContainer>
               <p>Oficina mecânica</p>
               <MainHeading>{store?.name}</MainHeading>
            </HeadingContainer>
            <Content>
               <form onSubmit={handleSimulate}>
                  <Label>
                     <label className="block text-sm font-medium leading-6 text-gray-900">Digite o valor </label>
                  </Label>
                  <InputContainer>
                     <Prefix>R$</Prefix>
                     <Input
                        type="text"
                        value={amount}
                        placeholder="0,00"
                        autoFocus={true}
                        onChange={(e) => {
                           formatValue(e.target.value)
                        }}
                        required
                     />
                  </InputContainer>
                  <br />
                  <hr />
                  <Label>
                     <label className="block text-sm font-medium leading-6 text-gray-900">
                        Digite a placa do seu veículo
                     </label>
                  </Label>
                  <InputContainer>
                     <Input2
                        type="text"
                        value={plate}
                        placeholder="ABC1234"
                        onChange={(e) => {
                           setPlate(e.target.value.toUpperCase())
                        }}
                        required
                     />
                     {/* <Tag>Opcional</Tag> */}
                  </InputContainer>

                  <ButtonContainer>
                     <PinkButton type="submit" className="btn btn-primary" disabled={!amount}>
                        Simular
                     </PinkButton>
                  </ButtonContainer>
               </form>
            </Content>
         </Container>
      </WrappedContainer>
   )
}

import axios from "api";
import { useQuery } from "hooks/query";
import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import { useParams } from "react-router-dom";
import { konduto } from "utils/konduto";

type OrderContextType = {
  fetched: boolean;
  protocol?: string;
  debts?: [any];
  store?: any;
  payment_plans?: [any];
  customer?: any;
  notFound?: boolean;
  setOrder: (value: any) => void;
};

const DEFAULT = {
  fetched: false,
  notFound: false,
};

const OrderContext = createContext<OrderContextType>({
  ...DEFAULT,
  setOrder: (value) => {},
});

export const useOrder = () => useContext(OrderContext);

type Props = {
  children: ReactNode;
};

export function OrderContextProvider({ children }: Props) {
  const [order, setOrder] = useState(DEFAULT);
  // const { protocol } = useParams<{protocol: string;}>();
  //const query = useQuery();

  // useEffect(() => {
  //   const key = query.get("p");
  //   const flow = query.get("plan-flow");
  //   const path = "/zapi/order/";

  //   axios
  //     .post(path, {"protocol": `${key}`})
  //     .then(({ data }) => {
  //       const { kpkey } = data;
  //       setOrder({
  //         ...data,
  //         fetched: true,
  //       });

  //       konduto(kpkey);        
  //     })
  //     .catch((error) => {
  //       setOrder({
  //         fetched: false,
  //         notFound: true,
  //       });
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <OrderContext.Provider value={{ ...order, setOrder }}>
      {children}
    </OrderContext.Provider>
  );
}

import tailwind from "twin.macro";
import { useTheme } from "styled-components";
import styled from "styled-components/macro";
import { useInstallments } from "../../../contexts/installments";
import { Theme } from "themes/types";

const ListItem = styled.li`
  ${tailwind`
    text-gray-900
    cursor-default
    select-none
    relative
    py-2
    pl-8
    pb-4
    
    border-b
  `}
  
  cursor: pointer;
 
`;

const Amount = styled.span`
  ${tailwind`
    font-bold
  `}
  
`;

const TotalAmount = styled.span`
  ${tailwind`
    text-right
    text-gray-800
    font-normal
  `}
`;

const Icon = styled.span`
  ${tailwind`
    text-green-600
    absolute
    inset-y-0
    left-0
    flex
    items-center
    pl-2
  `}

  svg {
    ${tailwind`
      h-4
      w-4
    `}
  }
`;

const Amounts = styled.div`
  ${tailwind`
    flex
    justify-between
    items-center
    gap-2
    pl-2
  `}
  
  div {
    ${tailwind`
      flex
      flex-row
      space-x-1
    `}
    p {
      ${tailwind`
        min-w-max
        font-bold
      `}
    }
  }

  
`;
const Total = styled.span`
  ${tailwind`
    text-sm
    pl-2
  `}
  color: #666666;
`;

type InstallmentOptionProps = {
  installments: number;
  fee: string;
  amount: number;
  totalAmount: number;
};

export default function InstallmentOptionFees({
  installments,
  fee,
  amount,
  totalAmount,
}: InstallmentOptionProps) {
  const { dataPlans, setSelected } = useInstallments();
  

  const isOptionSelected = dataPlans.selected === installments;
  

  const updatedAmount = amount;

  const updatedTotalAmount = totalAmount;
  const  displayFees  = true;

  return (
    <ListItem role="option" onClick={() => setSelected(installments)}>
      <Amounts>
        <div>
          <p className="font-extrabold">{installments}x de </p>
          <Amount>
            {updatedAmount.toLocaleString("pt-BR", {
              style: "decimal", 
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Amount>
        </div>
        <TotalAmount>
          Total: {updatedTotalAmount.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </TotalAmount>
      </Amounts>
      {displayFees && (
        <Total className="text-gray-400 ">
          {fee <= "1" ? "Sem juros" : `Taxa de ${String(fee).replace(".", ",")}%`}
        </Total>
      )}
      <Icon>

        {isOptionSelected ? (
          <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="3" fill="#E12C6D"/>
          <rect x="1" y="1" width="14" height="14" rx="7" stroke="#E12C6D" strokeWidth="1"/>
          </svg>
        ) : (
          <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="14" height="14" rx="7" fill="white"/>
            <rect x="1" y="1" width="14" height="14" rx="7" stroke="#d1d1d1" strokeWidth="1"/>
          </svg>
        )}
      </Icon>
      
    </ListItem>
    
  );
}

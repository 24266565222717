import React from "react";
import {
  Label,
  InputContainer,
  InputIconContainer,
  Input,
  InputIconErrorContainer,
  InputError,
  InputGroupContainer,
} from "./styles";
import { INPUT_CREDIT_CARD_HOLDER_NAME } from "./constants";

type HolderProps = {
  register: Function;
  errors: any;
};

export default function Holder({ register, errors }: HolderProps) {
  return (
    <InputGroupContainer>
      <Label error={errors} htmlFor={INPUT_CREDIT_CARD_HOLDER_NAME}>
        Nome impresso no cartão
      </Label>
      <InputContainer>
        <InputIconContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
        </InputIconContainer>
        <Input
          type="text"
          autoComplete="cc-name"
          placeholder="Ex.: Mariana Silva"
          {...register(INPUT_CREDIT_CARD_HOLDER_NAME, {
            required: {
              value: true,
              message: "Insira o nome impresso no cartão.",
            },
          })}
          error={errors}
        />
        {errors && (
          <InputIconErrorContainer>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </InputIconErrorContainer>
        )}
      </InputContainer>
      {errors && <InputError>{errors?.message}</InputError>}
    </InputGroupContainer>
  );
}
